///////////////////////////////////////////////////////////////////////////////////MODULES
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  CustomButton,
  CustomInput,
  FormGroup,
  Label,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Form,
  Spinner,
  CustomPagination,
} from '@ibiliaze/reactstrap';
import { useTranslation } from 'react-i18next';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { getComplaints, putComplaint, deleteComplaint } from '../../../actions/complaints';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import ComplaintInputsTable from './ComplaintInputsTable';
import ComplaintsHeader from './ComplaintsHeader';
import ComplaintsList from './ComplaintsList';
//////////////////////////////////////////////////////////////////////////////////////////

const Complaints = ({ complaints, getComplaints, putComplaint, deleteComplaint }) => {
  // State
  const [loading, setLoading] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [page, setPage] = useState(1);
  const [inputs, setInputs] = useState({
    mechanicId: '',
    reviewed: '',
    sortBy: '',
  });
  const [complaint, setComplaint] = useState(null);

  // Toggle functions
  const toggle = _ => setCanvas(!canvas);

  // Translation
  const [t] = useTranslation('global');

  // onChange functions
  const onInputsChange = e => setInputs(c => ({ ...c, [e.target.name]: e.target.value }));

  // onClick functions
  const onComplaintClick = t => {
    toggle();
    setComplaint(t);
  };
  const onPageClick = (e, index) => {
    e.preventDefault();
    index < 1 ? setPage(1) : setPage(index);
  };
  const onDeleteClick = async _ => await deleteComplaint(complaint?._id);

  // Lifecycle hooks
  useEffect(() => {
    const req = async _ => {
      try {
        setLoading(true);
        const mechanicIdEndpoint = !!inputs.mechanicId ? `&mechanicId=${inputs.mechanicId}` : '';
        const sortEndpoint = `&sortBy=${inputs.sortBy}`;
        const verificationEndpoint =
          inputs.reviewed === 'All' || !inputs.reviewed
            ? ''
            : inputs.reviewed === 'Reviewed'
            ? '&reviewed=true'
            : '&reviewed=false';
        const limitEndpoint = `&limit=20&skip=${20 * (page - 1)}`;

        await getComplaints('?' + mechanicIdEndpoint + limitEndpoint + verificationEndpoint + sortEndpoint);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };
    req();
  }, [page, inputs]);

  // onSubmit functions
  const onSubmit = async e => {
    e.preventDefault();
    try {
      await putComplaint({ complaintId: complaint._id, reviewed: complaint.reviewed });
    } catch (e) {
      console.error(e);
    }
  };

  // JSX
  return (
    <>
      <ComplaintsHeader inputs={inputs} onInputsChange={onInputsChange} />

      <CustomPagination page={page} onPageClick={onPageClick} />

      {loading ? (
        <Spinner color='primary' />
      ) : (
        <ComplaintsList complaints={complaints} onComplaintClick={onComplaintClick} />
      )}

      {!!complaint && typeof complaint === 'object' && (
        <Offcanvas direction='start' fade scrollable isOpen={canvas} toggle={toggle}>
          <OffcanvasHeader toggle={toggle}>Complaint Manager</OffcanvasHeader>
          <OffcanvasBody>
            <ComplaintInputsTable complaint={complaint} />

            <Form onSubmit={onSubmit}>
              <FormGroup>
                <CustomInput
                  type='checkbox'
                  name='reviewed'
                  checked={complaint.reviewed}
                  onChange={e => setComplaint(c => ({ ...c, reviewed: e.target.checked }))}
                />{' '}
                <Label>Reviewed</Label>
              </FormGroup>
              <CustomButton type='submit' color='primary'>
                {t('buttons.save')}
              </CustomButton>{' '}
              <CustomButton onClick={onDeleteClick} color='danger'>
                {t('buttons.delete')}
              </CustomButton>
            </Form>
          </OffcanvasBody>
        </Offcanvas>
      )}
    </>
  );
};

const mapStateToProps = state => ({ complaints: state.complaints });

export default connect(mapStateToProps, { getComplaints, putComplaint, deleteComplaint })(Complaints);
