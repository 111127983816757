/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from '../actions/types/types';
//////////////////////////////////////////////////////////////////LOGS DEFAULT STATE
const logsDefaultState = [];
////////////////////////////////////////////////////////////////////////LOGS REDUCER
const logs = (state = logsDefaultState, action) => {
  switch (action.type) {
    // GET request
    case types.GET_LOGS_SUCCESS:
      return Array.isArray(action.logs) ? [...action.logs] : [action.logs];
    case types.GET_LOGS_FAILURE:
      return [...state];

    // POST request
    case types.POST_LOG_SUCCESS:
      return [...state, action.log];
    case types.POST_LOG_FAILURE:
      return [...state];

    // PUT request
    case types.PUT_LOG_SUCCESS:
      return state.map(log => {
        if (log._id === action.log._id) {
          return { ...log, ...action.log };
        } else {
          return log;
        }
      });
    case types.PUT_LOG_FAILURE:
      return [...state];

    // DELETE request
    case types.DELETE_LOG_SUCCESS:
      return state.filter(log => log._id !== action.log._id);
    case types.DELETE_LOG_FAILURE:
      return [...state];

    default:
      return state;
  }
};

export default logs;
