///////////////////////////////////////////////////////////////////////////////////MODULES
import { api } from '../utils/httpRequest';
import errorParser from '@ibiliaze/http-error-parser';
/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from './types/types';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from './alert';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from '../utils/resolveEnv';
//////////////////////////////////////////////////////////////////////////////////////////

// GET /api/lead
export const getLeadsSuccess = leads => ({ type: types.GET_LEADS_SUCCESS, leads });
export const getLeadsFailure = errorMessage => ({ type: types.GET_LEADS_FAILURE, errorMessage });
export const getLeads = endpoint => async dispatch => {
  try {
    const res = await api.get(!endpoint ? `${resolveHost()}/api/lead` : `${resolveHost()}/api/lead${endpoint}`);

    dispatch(getLeadsSuccess(res.data));
  } catch (e) {
    dispatch(getLeadsFailure(e.message));
  }
};

// POST /api/lead
export const postLeadSuccess = lead => ({ type: types.POST_LEAD_SUCCESS, lead });
export const postLeadFailure = errorMessage => ({ type: types.POST_LEAD_FAILURE, errorMessage });
export const postLead = data => async dispatch => {
  try {
    dispatch(setAlert('Saving the lead...', false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/lead`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.POST_LEAD_SUCCESS));
    dispatch(postLeadSuccess(res.data.lead));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.POST_LEAD_FAILURE));
    dispatch(postLeadFailure(e.message));
  }
};

// PUT /api/lead
export const putLeadSuccess = lead => ({ type: types.PUT_LEAD_SUCCESS, lead });
export const putLeadFailure = errorMessage => ({ type: types.PUT_LEAD_FAILURE, errorMessage });
export const putLead = data => async dispatch => {
  try {
    dispatch(setAlert('Updating the lead...', false, null, null, true));
    const res = await api.put(`${resolveHost()}/api/lead/${data.leadId}`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.PUT_LEAD_SUCCESS));
    dispatch(putLeadSuccess(res.data.lead));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.PUT_LEAD_FAILURE));
    dispatch(putLeadFailure(e.message));
  }
};

// DELETE /api/lead
export const deleteLeadSuccess = lead => ({ type: types.DELETE_LEAD_SUCCESS, lead });
export const deleteLeadFailure = errorMessage => ({ type: types.DELETE_LEAD_FAILURE, errorMessage });
export const deleteLead = leadId => async dispatch => {
  try {
    dispatch(setAlert('Deleting the lead...', false, null, null, true));
    const res = await api.delete(`${resolveHost()}/api/lead/${leadId}`);

    dispatch(setAlert(res.data.message, false, res.status, types.DELETE_LEAD_SUCCESS));
    dispatch(deleteLeadSuccess(res.data.lead));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.DELETE_LEAD_FAILURE));
    dispatch(deleteLeadFailure(e.message));
  }
};
