///////////////////////////////////////////////////////////////////////////////////MODULES
import {
  Badge,
  CustomListGroup,
  CustomListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from '@ibiliaze/reactstrap';
//////////////////////////////////////////////////////////////////////////////////////////

const MechanicsList = ({ users, onMechanicClick }) => (
  <CustomListGroup>
    {users.map((mechanic, i) => (
      <CustomListGroupItem
        key={i}
        onClick={_ => onMechanicClick(mechanic)}
        green={mechanic.status === 'Verified'}
        blue={mechanic.status === 'Reviewed'}
        yellow={mechanic.status === 'In review'}
        red={mechanic.status === 'Not verified'}
      >
        <ListGroupItemHeading>
          {mechanic.businessName}{' '}
          <Badge
            className='t-f-s-s'
            color={
              mechanic.status === 'Not verified'
                ? 'danger'
                : mechanic.status === 'In review'
                ? 'warning'
                : mechanic.status === 'Reviewed'
                ? 'info'
                : 'success'
            }
            pill
          >
            {mechanic.status}
          </Badge>
        </ListGroupItemHeading>
        <ListGroupItemText className='r-gray t-f-s-ss'>{mechanic.compType}</ListGroupItemText>
      </CustomListGroupItem>
    ))}
  </CustomListGroup>
);

export default MechanicsList;
