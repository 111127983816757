/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from '../actions/types/types';
///////////////////////////////////////////////////////////////////////LEADS DEFAULT STATE
const leadsDefaultState = [];
/////////////////////////////////////////////////////////////////////////////LEADS REDUCER
const leads = (state = leadsDefaultState, action) => {
  switch (action.type) {
    // GET request
    case types.GET_LEADS_SUCCESS:
      return Array.isArray(action.leads) ? [...action.leads] : [action.leads];
    case types.GET_LEADS_FAILURE:
      return [...state];

    // POST request
    case types.POST_LEAD_SUCCESS:
      return [...state, action.lead];
    case types.POST_LEAD_FAILURE:
      return [...state];

    // PUT request
    case types.PUT_LEAD_SUCCESS:
      return state.map(lead => {
        if (lead._id === action.lead._id) {
          return { ...lead, ...action.lead };
        } else {
          return lead;
        }
      });
    case types.PUT_LEAD_FAILURE:
      return [...state];

    // DELETE request
    case types.DELETE_LEAD_SUCCESS:
      return state.filter(lead => lead._id !== action.lead._id);
    case types.DELETE_LEAD_FAILURE:
      return [...state];

    default:
      return state;
  }
};

export default leads;
