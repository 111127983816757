///////////////////////////////////////////////////////////////////////////////////MODULES
import { AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion } from '@ibiliaze/reactstrap';
//////////////////////////////////////////////////////////////////////////////////////////

const LogsList = ({ logs }) => (
  <UncontrolledAccordion>
    {logs?.map((l, i) => (
      <AccordionItem key={i}>
        <AccordionHeader targetId={i} size='sm'>
          {l.action} - {l.createdAt}
        </AccordionHeader>

        <AccordionBody accordionId={i}>
          <pre className='user-section'>{JSON.stringify(l.object, 0, 2)}</pre>
        </AccordionBody>
      </AccordionItem>
    ))}
  </UncontrolledAccordion>
);

export default LogsList;
