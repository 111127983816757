///////////////////////////////////////////////////////////////////////////////////MODULES
import { Table } from '@ibiliaze/reactstrap';
import { useTranslation } from 'react-i18next';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const LeadInputsTable = ({ lead }) => {
  // Translation
  const [t] = useTranslation('global');
  const skills = t('skills', { returnObjects: true });

  // JSX
  return (
    <Table borderless>
      <tbody>
        <tr>
          <th scope='row' className='t-f-s-l'>
            {t('review.clientDetails')}
          </th>
          <td />
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t('inputs.email.label')}
          </th>
          <td className='t-align-r'>{lead.email}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t('mechanic.tel')}
          </th>
          <td className='t-align-r'>{lead.tel}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t('mechanic.name')}
          </th>
          <td className='t-align-r'>{lead.name}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            IP
          </th>
          <td className='t-align-r'>{lead.ip}</td>
        </tr>

        <tr>
          <th scope='row' className='t-f-s-l'>
            {t('lead.leadDetails')}
          </th>
          <td />
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t(c.profession)} ID
          </th>
          <td className='t-align-r'>{lead.mechanicId}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t('general.skill')}
          </th>
          <td className='t-align-r'>{t(skills[lead.skill])}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t('mechanic.description')}
          </th>
          <td className='t-align-r'>{lead.body}</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default LeadInputsTable;
