///////////////////////////////////////////////////////////////////////////////////MODULES
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  CustomButton,
  CustomInput,
  FormGroup,
  Label,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Form,
  Spinner,
  CustomPagination,
} from '@ibiliaze/reactstrap';
import { useTranslation } from 'react-i18next';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { getReviews, putReview, deleteReview } from '../../../actions/reviews';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import ReviewInputsTable from './ReviewInputsTable';
import ReviewsHeader from './ReviewsHeader';
import ReviewsList from './ReviewsList';
//////////////////////////////////////////////////////////////////////////////////////////

const Settings = ({ reviews, getReviews, putReview, deleteReview }) => {
  // State
  const [loading, setLoading] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [page, setPage] = useState(1);
  const [inputs, setInputs] = useState({
    mechanicId: '',
    verified: '',
    sortBy: '',
  });
  const [review, setReview] = useState(null);

  // Toggle functions
  const toggle = _ => setCanvas(!canvas);

  // Translation
  const [t] = useTranslation('global');

  // onChange functions
  const onInputsChange = e => setInputs(c => ({ ...c, [e.target.name]: e.target.value }));

  // onClick functions
  const onReviewClick = t => {
    toggle();
    setReview(t);
  };
  const onPageClick = (e, index) => {
    e.preventDefault();
    index < 1 ? setPage(1) : setPage(index);
  };
  const onDeleteClick = async _ => await deleteReview(review?._id);

  // Lifecycle hooks
  useEffect(() => {
    const req = async _ => {
      try {
        setLoading(true);
        const mechanicIdEndpoint = !!inputs.mechanicId ? `&mechanicId=${inputs.mechanicId}` : '';
        const sortEndpoint = `&sortBy=${inputs.sortBy}`;
        const verificationEndpoint =
          inputs.verified === 'All' || !inputs.verified
            ? ''
            : inputs.verified === 'Verified'
            ? '&verified=true'
            : '&verified=false';
        const limitEndpoint = `&limit=20&skip=${20 * (page - 1)}`;

        await getReviews('?' + mechanicIdEndpoint + limitEndpoint + verificationEndpoint + sortEndpoint);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };
    req();
  }, [page, inputs]);

  // onSubmit functions
  const onSubmit = async e => {
    e.preventDefault();
    try {
      await putReview({ endpoint: `/verify/${review._id}`, verified: review.verified });
    } catch (e) {
      console.error(e);
    }
  };

  // JSX
  return (
    <>
      <ReviewsHeader inputs={inputs} onInputsChange={onInputsChange} />

      <CustomPagination page={page} onPageClick={onPageClick} />

      {loading ? <Spinner color='primary' /> : <ReviewsList reviews={reviews} onReviewClick={onReviewClick} />}

      {!!review && typeof review === 'object' && (
        <Offcanvas direction='start' fade scrollable isOpen={canvas} toggle={toggle}>
          <OffcanvasHeader toggle={toggle}>{t('review.reviewManager')}</OffcanvasHeader>
          <OffcanvasBody>
            <ReviewInputsTable review={review} />

            <Form onSubmit={onSubmit}>
              <FormGroup>
                <CustomInput
                  type='checkbox'
                  name='verified'
                  checked={review.verified}
                  onChange={e => setReview(c => ({ ...c, verified: e.target.checked }))}
                />{' '}
                <Label>{t('general.verified')}</Label>
              </FormGroup>
              <CustomButton type='submit' color='primary'>
                {t('buttons.save')}
              </CustomButton>{' '}
              <CustomButton onClick={onDeleteClick} color='danger'>
                {t('buttons.delete')}
              </CustomButton>
            </Form>
          </OffcanvasBody>
        </Offcanvas>
      )}
    </>
  );
};

const mapStateToProps = state => ({ reviews: state.reviews });

export default connect(mapStateToProps, { getReviews, putReview, deleteReview })(Settings);
