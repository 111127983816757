const sizeMap = {
  xxs: '15px',
  xs: '20px',
  s: '25px',
  m: '30px',
  l: '60px',
  xl: '77px',
  xxl: '94px',
};

// Header
export const Language = ({ size = 'xl' }) => (
  <img src='icons/language.png' alt='language' style={{ height: sizeMap[size] }} />
);

// Home Page
export const Check = ({ size = 'xl' }) => <img src='icons/check.png' alt='check' style={{ height: sizeMap[size] }} />;
export const Zoom = ({ size = 'xl' }) => <img src='icons/zoom.png' alt='zoom' style={{ height: sizeMap[size] }} />;
export const First = ({ size = 'xl' }) => <img src='icons/1st.png' alt='1st' style={{ height: sizeMap[size] }} />;
export const Speech = ({ size = 'xl' }) => (
  <img src='icons/speech.png' alt='speech' style={{ height: sizeMap[size] }} />
);

// Services
export const Engine = ({ size = 'xl' }) => (
  <img src='icons/engine.png' alt='engine' style={{ height: sizeMap[size] }} />
);
export const Electric = ({ size = 'xl' }) => (
  <img src='icons/electric.png' alt='electric' style={{ height: sizeMap[size] }} />
);
export const Brush = ({ size = 'xl' }) => <img src='icons/brush.png' alt='brush' style={{ height: sizeMap[size] }} />;
export const Service = ({ size = 'xl' }) => (
  <img src='icons/service.png' alt='service' style={{ height: sizeMap[size] }} />
);
export const Tire = ({ size = 'xl' }) => <img src='icons/tire.png' alt='tire' style={{ height: sizeMap[size] }} />;
export const Wheel = ({ size = 'xl' }) => <img src='icons/wheel.png' alt='wheel' style={{ height: sizeMap[size] }} />;
