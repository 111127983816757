///////////////////////////////////////////////////////////////////////////////////MODULES
import { useEffect, useState } from 'react';
import {
  Col,
  CustomInput,
  FormGroup,
  Label,
  CustomListGroup,
  CustomListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Table,
} from '@ibiliaze/reactstrap';
import { useTranslation } from 'react-i18next';
import { FcMoneyTransfer, FcIphone, FcMultipleInputs } from 'react-icons/fc';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import EditSaveButton from './EditSaveButton';
//////////////////////////////////////////////////////////////////////////////////////////

const Services = ({ user, isAuthenticated, putUser }) => {
  // State
  const [edit, setEdit] = useState(false);
  const [inputs, setInputs] = useState({
    quoteType: '',
    callOut: '',
    paymentTypes: '',
    primarySkill: '',
    secondarySkill: '',
    tertiarySkill: '',
    extraSkill: '',
  });

  // Translation
  const [t] = useTranslation('global');
  const skills = t('skills', { returnObjects: true });
  const quoteTypeOptions = t('options.quoteTypeOptions', { returnObjects: true });
  const paymentTypeOptions = t('options.paymentTypeOptions', { returnObjects: true });

  // onChange functions
  const onInputsChange = e => setInputs(c => ({ ...c, [e.target.name]: e.target.value }));

  // Lifecycle hooks
  useEffect(() => {
    try {
      setInputs({
        quoteType: user?.quoteType,
        callOut: user?.callOut,
        paymentTypes: user?.paymentTypes,
        primarySkill: user?.primarySkill,
        secondarySkill: user?.secondarySkill,
        tertiarySkill: user?.tertiarySkill,
        extraSkill: user?.extraSkill,
      });
    } catch (e) {
      console.error(e);
    }
  }, [user]);

  // JSX
  return (
    <>
      {isAuthenticated && <EditSaveButton inputs={inputs} edit={edit} setEdit={setEdit} putUser={putUser} />}

      <h5 className='t-align-c'>{t('general.services')}</h5>
      <hr />
      <Col md={8}>
        <Table borderless>
          <tbody>
            <tr>
              <th scope='row' className='t-gray'>
                <FcMoneyTransfer className='s-icon' /> {t('mechanic.quoteType')}
              </th>
              <td>
                {isAuthenticated && edit ? (
                  <FormGroup>
                    <CustomInput type='select' name='quoteType' value={inputs.quoteType} onChange={onInputsChange}>
                      <option value={t('options.select.value')}>{t('options.select.label')}</option>
                      {quoteTypeOptions.map((o, i) => (
                        <option key={i} value={o.value}>
                          {o.label}
                        </option>
                      ))}
                    </CustomInput>
                  </FormGroup>
                ) : (
                  !!user?.quoteType && quoteTypeOptions.find(o => o.value === user?.quoteType)?.label
                )}
              </td>
            </tr>

            <tr>
              <th scope='row' className='t-gray'>
                <FcMultipleInputs className='s-icon' /> {t('mechanic.paymentTypes')}
              </th>
              <td>
                {isAuthenticated && edit ? (
                  <FormGroup>
                    <CustomInput
                      type='select'
                      name='paymentTypes'
                      value={inputs.paymentTypes}
                      onChange={onInputsChange}
                    >
                      <option value={t('options.select.value')}>{t('options.select.label')}</option>
                      {paymentTypeOptions.map((o, i) => (
                        <option key={i} value={o.value}>
                          {o.label}
                        </option>
                      ))}
                    </CustomInput>
                  </FormGroup>
                ) : (
                  !!user?.paymentTypes && paymentTypeOptions.find(o => o.value === user?.paymentTypes)?.label
                )}
              </td>
            </tr>

            <tr>
              <th scope='row' className='t-gray'>
                <FcIphone className='s-icon' /> {t('mechanic.callOut')}
              </th>
              <td>
                {isAuthenticated && edit ? (
                  <FormGroup>
                    <CustomInput type='select' name='callOut' value={inputs.callOut} onChange={onInputsChange}>
                      <option value={t('options.select.value')}>{t('options.select.label')}</option>
                      {c.inputsOptions.callOut.map((val, i) => (
                        <option key={i}>{val}</option>
                      ))}
                    </CustomInput>
                  </FormGroup>
                ) : (
                  user?.callOut
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>

      <br />
      <h5 className='t-align-c'>{t('general.skills')}</h5>
      <hr />
      {isAuthenticated && edit ? (
        <>
          <FormGroup floating>
            <CustomInput
              required
              type='select'
              name='primarySkill'
              value={inputs.primarySkill}
              placeholder=''
              onChange={onInputsChange}
            >
              <option />
              {Object.keys(skills).map((key, i) => (
                <option key={i} value={key} label={skills[key]} />
              ))}
            </CustomInput>
            <Label>{t('mechanic.primarySkill')}</Label>
          </FormGroup>

          <FormGroup floating>
            <CustomInput
              required
              type='select'
              name='secondarySkill'
              value={inputs.secondarySkill}
              placeholder=''
              onChange={onInputsChange}
            >
              <option />
              {Object.keys(skills).map((key, i) => (
                <option key={i} value={key} label={skills[key]} />
              ))}
            </CustomInput>
            <Label>{t('mechanic.secondarySkill')}</Label>
          </FormGroup>

          <FormGroup floating>
            <CustomInput
              required
              type='select'
              name='tertiarySkill'
              value={inputs.tertiarySkill}
              placeholder=''
              onChange={onInputsChange}
            >
              <option />
              {Object.keys(skills).map((key, i) => (
                <option key={i} value={key} label={skills[key]} />
              ))}
            </CustomInput>
            <Label>{t('mechanic.tertiarySkill')}</Label>
          </FormGroup>

          <FormGroup floating>
            <CustomInput
              required
              type='select'
              name='extraSkill'
              value={inputs.extraSkill}
              placeholder=''
              onChange={onInputsChange}
            >
              <option />
              {Object.keys(skills).map((key, i) => (
                <option key={i} value={key} label={skills[key]} />
              ))}
            </CustomInput>
            <Label>{t('mechanic.extraSkill')}</Label>
          </FormGroup>
        </>
      ) : (
        <CustomListGroup>
          <CustomListGroupItem>
            <ListGroupItemHeading className='t-green'>{skills[inputs.primarySkill]}</ListGroupItemHeading>
            <ListGroupItemText className='t-gray t-f-s-s'>{t('mechanic.primarySkill')}</ListGroupItemText>
          </CustomListGroupItem>

          <CustomListGroupItem>
            <ListGroupItemHeading>{skills[inputs.secondarySkill]}</ListGroupItemHeading>
            <ListGroupItemText className='t-gray t-f-s-s'>{t('mechanic.secondarySkill')}</ListGroupItemText>
          </CustomListGroupItem>

          <CustomListGroupItem>
            <ListGroupItemHeading>{skills[inputs.tertiarySkill]}</ListGroupItemHeading>
            <ListGroupItemText className='t-gray t-f-s-s'>{t('mechanic.tertiarySkill')}</ListGroupItemText>
          </CustomListGroupItem>

          <CustomListGroupItem>
            <ListGroupItemHeading>{skills[inputs.extraSkill]}</ListGroupItemHeading>
            <ListGroupItemText className='t-gray t-f-s-s'>{t('mechanic.extraSkill')}</ListGroupItemText>
          </CustomListGroupItem>
        </CustomListGroup>
      )}
    </>
  );
};

export default Services;
