///////////////////////////////////////////////////////////////////////////////////MODULES
import { api } from '../utils/httpRequest';
import errorParser from '@ibiliaze/http-error-parser';
/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from './types/types';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from './alert';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from '../utils/resolveEnv';
//////////////////////////////////////////////////////////////////////////////////////////

// GET /api/log
export const getLogsSuccess = logs => ({ type: types.GET_LOGS_SUCCESS, logs });
export const getLogsFailure = errorMessage => ({ type: types.GET_LOGS_FAILURE, errorMessage });
export const getLogs = endpoint => async dispatch => {
  try {
    const res = await api.get(!endpoint ? `${resolveHost()}/api/log` : `${resolveHost()}/api/log${endpoint}`);

    dispatch(getLogsSuccess(res.data));
  } catch (e) {
    dispatch(getLogsFailure(e.message));
  }
};

// POST /api/log
export const postLogSuccess = log => ({ type: types.POST_LOG_SUCCESS, log });
export const postLogFailure = errorMessage => ({ type: types.POST_LOG_FAILURE, errorMessage });
export const postLog = data => async dispatch => {
  try {
    dispatch(setAlert('Saving the log...', false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/log`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.POST_LOG_SUCCESS));
    dispatch(postLogSuccess(res.data.log));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.POST_LOG_FAILURE));
    dispatch(postLogFailure(e.message));
  }
};

// PUT /api/log
export const putLogSuccess = log => ({ type: types.PUT_LOG_SUCCESS, log });
export const putLogFailure = errorMessage => ({ type: types.PUT_LOG_FAILURE, errorMessage });
export const putLog = data => async dispatch => {
  try {
    dispatch(setAlert('Updating the log...', false, null, null, true));
    const res = await api.put(`${resolveHost()}/api/log/${data.logId}`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.PUT_LOG_SUCCESS));
    dispatch(putLogSuccess(res.data.log));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.PUT_LOG_FAILURE));
    dispatch(putLogFailure(e.message));
  }
};

// DELETE /api/log
export const deleteLogSuccess = log => ({ type: types.DELETE_LOG_SUCCESS, log });
export const deleteLogFailure = errorMessage => ({ type: types.DELETE_LOG_FAILURE, errorMessage });
export const deleteLog = logId => async dispatch => {
  try {
    dispatch(setAlert('Deleting the log...', false, null, null, true));
    const res = await api.delete(`${resolveHost()}/api/log/${logId}`);

    dispatch(setAlert(res.data.message, false, res.status, types.DELETE_LOG_SUCCESS));
    dispatch(deleteLogSuccess(res.data.log));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.DELETE_LOG_FAILURE));
    dispatch(deleteLogFailure(e.message));
  }
};
