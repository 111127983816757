///////////////////////////////////////////////////////////////////////////////////MODULES
import {
  Badge,
  CustomListGroup,
  CustomListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from '@ibiliaze/reactstrap';
//////////////////////////////////////////////////////////////////////////////////////////

const ComplaintsList = ({ complaints, onComplaintClick }) => (
  <CustomListGroup>
    {complaints.map((r, i) => (
      <CustomListGroupItem key={i} onClick={_ => onComplaintClick(r)} green={r.reviewed} red={!r.reviewed}>
        <ListGroupItemHeading>
          {r.email}{' '}
          <Badge className='r-f-s-s' color={r.reviewed ? 'success' : 'danger'} pill>
            {r.reviewed ? 'Reviewed' : 'Not reviewed'}
          </Badge>
        </ListGroupItemHeading>
        <ListGroupItemText className='r-gray t-f-s-ss'>{r.createdAt}</ListGroupItemText>
      </CustomListGroupItem>
    ))}
  </CustomListGroup>
);

export default ComplaintsList;
