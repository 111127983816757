import { Check, First, Speech } from './icons';

export const env = process.env;

const c = {
  // GENERAL INFO
  cust: 'Next Auto Shop',
  custSm: 'nextautoshop',
  custJs: 'nextautoshop',
  domain: 'nextautoshop.com',
  slogan: 'home.slogan',
  phone1: '+447570695235',
  email1: 'info@nextautoshop.com',
  hqAddr: '4 Dereham Road, IG11 9HA, Barking, Essex',
  chatPerson: 'Faha',
  chatMessage: `Hello there! \n \nHow can we help?`,
  chatStatus: 'Typically replies within a few minutes',
  profession: 'general.profession',
  paymentTypes: ['card', 'cash'],
  currency: '€',
  darkMode: true,
  defaultLanguage: 'en',

  // INPUT OPTIONS
  inputsOptions: {
    noe: ['1', '2-4', '5-9', '10-24', '25+'],
  },

  // MAIN PIC
  mainPic: {
    path: '/img/main.jpg',
    height: '500px',
    mobileHeight: '300px',
    logo: '/img/logo.png',
    buttonText: 'home.mainImageButton',
    inputPlaceHolder: 'home.mainImageInputPlaceHolder',
    mobileWidth: 767,
  },

  // SOCIALS
  socials: {
    instagram: 'nextautoshopldn',
  },

  // HEADER
  header: {
    blur: '1px',
    logo: '/img/logo-white.png',
    dark: true,
    styles: { height: '40px' },
    fadeOnPaths: ['/', '/auth', '/profile', '/faq', '/make-a-complaint', '/why-us', '/amend-my-review'],
  },

  // CAROUSEL
  carousel: {
    dark: true,
    fade: false,
    indicators: false,
  },

  // ABOUTS
  abouts: [
    {
      header: 'home.abouts.1',
      body: 'home.abouts.1d',
      img: '/img/leaveareview.jpg',
      alt: 'leaveareview',
      icon: <Check size='l' />,
      onClick: _ => window.open('/why-us'),
    },
    {
      header: 'home.abouts.2',
      body: 'home.abouts.2d',
      img: '/img/mechanic.jpg',
      alt: 'mechanic',
      icon: <Speech size='l' />,
      onClick: _ => window.open('/why-us'),
    },
    {
      header: 'home.abouts.3',
      body: 'home.abouts.3d',
      img: '/img/signup.jpg',
      alt: 'signup',
      icon: <First size='l' />,
      onClick: _ => window.open('/why-us'),
    },
  ],

  // REACTSTRAP INPUT
  i: {
    d: false,
  },

  // REACTSTRAP BUTTONS
  b: {
    s: 'sm',
  },

  // REACTSTRAP PAGING
  p: {
    s: 'md',
  },
};

export default c;
