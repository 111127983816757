///////////////////////////////////////////////////////////////////////////////////MODULES
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  CustomInput,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormText,
} from '@ibiliaze/reactstrap';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { confirmEmail } from '../../actions/email';
import { postLead } from '../../actions/leads';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveRecaptchaSiteKey } from '../../utils/resolveEnv';
import verifyRecaptcha from '../../utils/verifyRecaptcha';
//////////////////////////////////////////////////////////////////////////////////////////

const QuoteModal = ({ mechanicId, modal, toggle, confirmEmail, postLead }) => {
  // State
  const [section, setSection] = useState('inputs');
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [inputs, setInputs] = useState({
    // Inputs section
    name: '',
    email: '',
    tel: '',
    skill: '',
    body: '',
    startTime: '',
    // Email OTP section
    emailCode: '',
  });

  // Translation
  const [t] = useTranslation('global');
  const skills = t('skills', { returnObjects: true });
  const jobStartTimesOptions = t('options.jobStartTimesOptions', { returnObjects: true });

  // onChange functions
  const onInputsChange = e => setInputs(c => ({ ...c, [e.target.name]: e.target.value }));
  const onRecaptchaChange = async value => setRecaptchaVerified(await verifyRecaptcha(value));

  // Lifecycle hooks
  useEffect(() => {
    const req = async _ => {
      if (section === 'emailOtp') {
        await confirmEmail({ email: inputs.email?.toLowerCase() });
      }
    };

    req();
  }, [section]);

  // onSubmit functions
  const onSubmit = async e => {
    e.preventDefault();

    try {
      if (section === 'inputs') {
        setSection('emailOtp');
      } else {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        await postLead({ ...inputs, mechanicId, ip: data.ip });
        toggle();
      }
    } catch (e) {
      console.error(e);
    }
  };

  // JSX
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <Form onSubmit={onSubmit}>
        <ModalHeader toggle={toggle}>{t('buttons.getQuote')}</ModalHeader>
        <ModalBody>
          {section === 'inputs' ? (
            <>
              <FormGroup floating>
                <CustomInput required name='name' placeholder='Name *' value={inputs.name} onChange={onInputsChange} />
                <Label>{t('mechanic.name')} *</Label>
              </FormGroup>

              <FormGroup floating>
                <CustomInput
                  required
                  type='email'
                  name='email'
                  placeholder=''
                  value={inputs.email}
                  onChange={onInputsChange}
                />
                <Label>{t('inputs.email.label')} *</Label>
              </FormGroup>

              <FormGroup floating>
                <CustomInput
                  required
                  placeholder=''
                  type='number'
                  name='tel'
                  value={inputs.tel}
                  onChange={onInputsChange}
                />
                <Label>{t('mechanic.tel')} *</Label>
              </FormGroup>

              <FormGroup floating>
                <CustomInput
                  required
                  type='select'
                  name='startTime'
                  value={inputs.startTime}
                  placeholder=''
                  onChange={onInputsChange}
                >
                  <option value={t('options.select.value')}>{t('options.select.label')}</option>
                  {jobStartTimesOptions.map((o, i) => (
                    <option key={i} value={o.value}>
                      {o.label}
                    </option>
                  ))}
                </CustomInput>
                <Label>{t('inputs.jobStartTimes.label')} *</Label>
              </FormGroup>

              <FormGroup floating>
                <CustomInput
                  required
                  type='select'
                  name='skill'
                  value={inputs.skill}
                  placeholder=''
                  onChange={onInputsChange}
                >
                  <option value={t('options.select.value')}>{t('options.select.label')}</option>
                  {Object.keys(skills).map((key, i) => (
                    <option key={i} value={key} label={skills[key]} />
                  ))}
                </CustomInput>
                <Label>{t('general.skill')}</Label>
              </FormGroup>

              <FormGroup>
                <Label>{t('inputs.description.label')} *</Label>
                <CustomInput
                  required
                  type='textarea'
                  rows='5'
                  placeholder={t('inputs.description.label')}
                  name='body'
                  value={inputs.body}
                  onChange={onInputsChange}
                />
              </FormGroup>
            </>
          ) : (
            <>
              <FormGroup floating>
                <CustomInput
                  required
                  placeholder=''
                  type='number'
                  name='emailCode'
                  value={inputs.emailCode}
                  onChange={onInputsChange}
                />
                <Label>{t('inputs.emailOtp.label')} *</Label>
                <FormText>{t('inputs.emailOtp.formtext')}</FormText>
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          {section === 'emailOtp' && (
            <ReCAPTCHA sitekey={resolveRecaptchaSiteKey()} onChange={onRecaptchaChange} className='m-auto' />
          )}
          {section !== 'inputs' && (
            <Button
              color='secondary'
              onClick={_ => setSection(section === 'emailOtp' ? 'inputs' : '')}
              className='w-100'
            >
              {t('buttons.back')}
            </Button>
          )}
          <Button
            disabled={section === 'emailOtp' && !recaptchaVerified}
            color='primary'
            type='submit'
            className='w-100'
          >
            {t('buttons.continue')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default connect(null, { confirmEmail, postLead })(QuoteModal);
