///////////////////////////////////////////////////////////////////////////////////MODULES
import { api } from '../utils/httpRequest';
import errorParser from '@ibiliaze/http-error-parser';
/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from './types/types';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from './alert';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from '../utils/resolveEnv';
//////////////////////////////////////////////////////////////////////////////////////////

// GET /api/complaint
export const getComplaintsSuccess = complaints => ({ type: types.GET_COMPLAINTS_SUCCESS, complaints });
export const getComplaintsFailure = errorMessage => ({ type: types.GET_COMPLAINTS_FAILURE, errorMessage });
export const getComplaints = endpoint => async dispatch => {
  try {
    const res = await api.get(
      !endpoint ? `${resolveHost()}/api/complaint` : `${resolveHost()}/api/complaint${endpoint}`
    );

    dispatch(getComplaintsSuccess(res.data));
  } catch (e) {
    dispatch(getComplaintsFailure(e.message));
  }
};

// POST /api/complaint
export const postComplaintSuccess = complaint => ({ type: types.POST_COMPLAINT_SUCCESS, complaint });
export const postComplaintFailure = errorMessage => ({ type: types.POST_COMPLAINT_FAILURE, errorMessage });
export const postComplaint = data => async dispatch => {
  try {
    dispatch(setAlert('Saving the complaint...', false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/complaint`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.POST_COMPLAINT_SUCCESS));
    dispatch(postComplaintSuccess(res.data.complaint));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.POST_COMPLAINT_FAILURE));
    dispatch(postComplaintFailure(e.message));
  }
};

// PUT /api/complaint
export const putComplaintSuccess = complaint => ({ type: types.PUT_COMPLAINT_SUCCESS, complaint });
export const putComplaintFailure = errorMessage => ({ type: types.PUT_COMPLAINT_FAILURE, errorMessage });
export const putComplaint = data => async dispatch => {
  try {
    dispatch(setAlert('Updating the complaint...', false, null, null, true));
    const res = await api.put(`${resolveHost()}/api/complaint/${data.complaintId}`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.PUT_COMPLAINT_SUCCESS));
    dispatch(putComplaintSuccess(res.data.complaint));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.PUT_COMPLAINT_FAILURE));
    dispatch(putComplaintFailure(e.message));
  }
};

// DELETE /api/complaint
export const deleteComplaintSuccess = complaint => ({ type: types.DELETE_COMPLAINT_SUCCESS, complaint });
export const deleteComplaintFailure = errorMessage => ({ type: types.DELETE_COMPLAINT_FAILURE, errorMessage });
export const deleteComplaint = complaintId => async dispatch => {
  try {
    dispatch(setAlert('Deleting the complaint...', false, null, null, true));
    const res = await api.delete(`${resolveHost()}/api/complaint/${complaintId}`);

    dispatch(setAlert(res.data.message, false, res.status, types.DELETE_COMPLAINT_SUCCESS));
    dispatch(deleteComplaintSuccess(res.data.complaint));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.DELETE_COMPLAINT_FAILURE));
    dispatch(deleteComplaintFailure(e.message));
  }
};
