///////////////////////////////////////////////////////////////////////////////////MODULES
import { Row, Col } from '@ibiliaze/reactstrap';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import SetImage from '../Layout/SetImage';
//////////////////////////////////////////////////////////////////////////////////////////

const Album = ({ user, isAuthenticated, putUser, setAlert }) => (
  <Row>
    <Col md={6}>
      <SetImage
        user={user}
        isAuthenticated={isAuthenticated}
        putUser={putUser}
        setAlert={setAlert}
        id='albumImg1'
        imgClass='album-img'
      />
    </Col>
    <Col md={6}>
      <Row>
        <Col md={6}>
          <SetImage
            user={user}
            isAuthenticated={isAuthenticated}
            putUser={putUser}
            setAlert={setAlert}
            id='albumImg2'
            imgClass='album-img'
          />
        </Col>
        <Col md={6}>
          <SetImage
            user={user}
            isAuthenticated={isAuthenticated}
            putUser={putUser}
            setAlert={setAlert}
            id='albumImg3'
            imgClass='album-img'
          />
        </Col>
        <Col md={6}>
          <SetImage
            user={user}
            isAuthenticated={isAuthenticated}
            putUser={putUser}
            setAlert={setAlert}
            id='albumImg4'
            imgClass='album-img'
          />
        </Col>
        <Col md={6}>
          <SetImage
            user={user}
            isAuthenticated={isAuthenticated}
            putUser={putUser}
            setAlert={setAlert}
            id='albumImg5'
            imgClass='album-img'
          />
        </Col>
      </Row>
    </Col>

    <Col md={4}>
      <SetImage
        user={user}
        isAuthenticated={isAuthenticated}
        putUser={putUser}
        setAlert={setAlert}
        id='albumImg6'
        imgClass='album-img'
      />
    </Col>
    <Col md={4}>
      <SetImage
        user={user}
        isAuthenticated={isAuthenticated}
        putUser={putUser}
        setAlert={setAlert}
        id='albumImg7'
        imgClass='album-img'
      />
    </Col>
    <Col md={4}>
      <SetImage
        user={user}
        isAuthenticated={isAuthenticated}
        putUser={putUser}
        setAlert={setAlert}
        id='albumImg8'
        imgClass='album-img'
      />
    </Col>

    <Col md={6}>
      <Row>
        <Col md={6}>
          <SetImage
            user={user}
            isAuthenticated={isAuthenticated}
            putUser={putUser}
            setAlert={setAlert}
            id='albumImg9'
            imgClass='album-img'
          />
        </Col>
        <Col md={6}>
          <SetImage
            user={user}
            isAuthenticated={isAuthenticated}
            putUser={putUser}
            setAlert={setAlert}
            id='albumImg10'
            imgClass='album-img'
          />
        </Col>
        <Col md={6}>
          <SetImage
            user={user}
            isAuthenticated={isAuthenticated}
            putUser={putUser}
            setAlert={setAlert}
            id='albumImg11'
            imgClass='album-img'
          />
        </Col>
        <Col md={6}>
          <SetImage
            user={user}
            isAuthenticated={isAuthenticated}
            putUser={putUser}
            setAlert={setAlert}
            id='albumImg12'
            imgClass='album-img'
          />
        </Col>
      </Row>
    </Col>
    <Col md={6}>
      <SetImage
        user={user}
        isAuthenticated={isAuthenticated}
        putUser={putUser}
        setAlert={setAlert}
        id='albumImg13'
        imgClass='album-img'
      />
    </Col>
  </Row>
);

export default Album;
