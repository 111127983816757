///////////////////////////////////////////////////////////////////////////////////MODULES
import { connect } from 'react-redux';
import { Banner as BannerRS } from '@ibiliaze/reactstrap';
import { ScaleIn } from '@ibiliaze/react-intersect';
//////////////////////////////////////////////////////////////////////////////////////////

const Banner = ({ settings }) =>
  !!settings &&
  settings?.global &&
  settings?.global?.showBanner && (
    <section className='page p-t-l p-b-l'>
      <ScaleIn>
        <BannerRS
          title={settings?.global?.bannerHeader}
          text={settings?.global?.bannerBody}
          style={{ background: settings?.global?.bannerColour, color: settings?.global?.bannerTextColour }}
        />
      </ScaleIn>
    </section>
  );

const mapStateToProps = state => ({ settings: state.settings });

export default connect(mapStateToProps)(Banner);
