///////////////////////////////////////////////////////////////////////////////////MODULES
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  CustomButton,
  CustomInput,
  CustomInputGroupText,
  Form,
  FormGroup,
  InputGroup,
  Label,
  Spinner,
} from '@ibiliaze/reactstrap';
import { useTranslation } from 'react-i18next';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { putSettings } from '../../actions/settings';
import c from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const Pricing = ({ settings, putSettings }) => {
  // State
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    primarySkill: 0,
    secondarySkill: 0,
    tertiarySkill: 0,
    extraSkill: 0,
    publicLeadView: 0,
  });

  // Translation
  const [t] = useTranslation('global');

  // onChange functions
  const onInputsChange = e => setInputs(c => ({ ...c, [e.target.name]: e.target.value }));

  // Lifecycle hooks
  useEffect(() => {
    try {
      setInputs({ ...settings.global });
    } catch (e) {
      console.error(e);
    }
  }, [settings]);

  // onSubmit functions
  const onSubmit = async e => {
    e.preventDefault();
    try {
      setLoading(true);
      await putSettings({ ...inputs, type: undefined });
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  // JSX
  return loading ? (
    <Spinner color='primary' />
  ) : (
    <Form onSubmit={onSubmit}>
      <FormGroup>
        <Label>Primary Skill</Label>
        <InputGroup>
          <CustomInputGroupText>{c.currency}</CustomInputGroupText>
          <CustomInput
            type='number'
            placeholder='100'
            name='primarySkill'
            value={inputs.primarySkill}
            onChange={onInputsChange}
          />
        </InputGroup>
      </FormGroup>

      <FormGroup>
        <Label>Secondary Skill</Label>
        <InputGroup>
          <CustomInputGroupText>{c.currency}</CustomInputGroupText>
          <CustomInput
            type='number'
            placeholder='100'
            name='secondarySkill'
            value={inputs.secondarySkill}
            onChange={onInputsChange}
          />
        </InputGroup>
      </FormGroup>

      <FormGroup>
        <Label>Tertiary Skill</Label>
        <InputGroup>
          <CustomInputGroupText>{c.currency}</CustomInputGroupText>
          <CustomInput
            type='number'
            placeholder='100'
            name='tertiarySkill'
            value={inputs.tertiarySkill}
            onChange={onInputsChange}
          />
        </InputGroup>
      </FormGroup>

      <FormGroup>
        <Label>Extra Skill</Label>
        <InputGroup>
          <CustomInputGroupText>{c.currency}</CustomInputGroupText>
          <CustomInput
            type='number'
            placeholder='100'
            name='extraSkill'
            value={inputs.extraSkill}
            onChange={onInputsChange}
          />
        </InputGroup>
      </FormGroup>

      <FormGroup>
        <Label>Public Lead View</Label>
        <InputGroup>
          <CustomInputGroupText>{c.currency}</CustomInputGroupText>
          <CustomInput
            type='number'
            placeholder='100'
            name='publicLeadView'
            value={inputs.publicLeadView}
            onChange={onInputsChange}
          />
        </InputGroup>
      </FormGroup>

      <FormGroup>
        <CustomButton type='submit' color='primary'>
          {t('buttons.save')}
        </CustomButton>
      </FormGroup>
    </Form>
  );
};

const mapStateToProps = state => ({ settings: state.settings });

export default connect(mapStateToProps, { putSettings })(Pricing);
