///////////////////////////////////////////////////////////////////////////////////MODULES
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
////////////////////////////////////////////////////////////////////////////////APP ROUTER
import AppRouter from './routers/AppRouter';
/////////////////////////////////////////////////////////////////////////////////////STORE
import configureStore from './store/configureStore';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { getUser } from './actions/user';
import { getAdmin } from './actions/admin';
import { getSettings } from './actions/settings';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from './utils/constants';
//////////////////////////////////////////////////////////////////////////////TRANSLATIONS
import global_en from './locales/en/translation.json';
import global_fr from './locales/fr/translation.json';
import global_nl from './locales/nl/translation.json';
//////////////////////////////////////////////////////////////////////////////////////////

i18next.init({
  interpolation: { escapeValue: false },
  lng: c.defaultLanguage,
  resources: {
    en: {
      global: global_en,
    },
    fr: {
      global: global_fr,
    },
    nl: {
      global: global_nl,
    },
  },
});

document.getElementsByName('viewport')[0].content = 'width=device-width, initial-scale=1, maximum-scale=1';

const store = configureStore();
const App = () => {
  useEffect(() => {
    const request = async () => {
      try {
        await store.dispatch(getUser());
        await store.dispatch(getAdmin());
        await store.dispatch(getSettings('?type=global'));
      } catch (error) {
        console.log(error);
      }
    };

    request();
  }, []);

  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <AppRouter />
      </I18nextProvider>
    </Provider>
  );
};

export default App;
