///////////////////////////////////////////////////////////////////////////////////MODULES
import { Col, CustomInput, FormGroup, Table } from '@ibiliaze/reactstrap';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FcCellPhone, FcExternal } from 'react-icons/fc';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import EditSaveButton from './EditSaveButton';
//////////////////////////////////////////////////////////////////////////////////////////

const Contact = ({ user, isAuthenticated, putUser }) => {
  // State
  const [edit, setEdit] = useState(false);
  const [inputs, setInputs] = useState({
    tel: '',
    tel2: '',
    tel3: '',
    website: '',
    showEmail: false,
  });

  // Translation
  const [t] = useTranslation('global');

  // onChange functions
  const onInputsChange = e => setInputs(c => ({ ...c, [e.target.name]: e.target.value }));

  // Lifecycle hooks
  useEffect(() => {
    try {
      setInputs({
        tel: user?.tel,
        tel2: user?.tel2,
        tel3: user?.tel3,
        website: user?.website,
        showEmail: user?.showEmail,
      });
    } catch (e) {
      console.error(e);
    }
  }, [user]);

  // JSX
  return (
    <>
      {isAuthenticated && <EditSaveButton inputs={inputs} edit={edit} setEdit={setEdit} putUser={putUser} />}

      <Col md={8}>
        <Table borderless>
          <tbody>
            <tr>
              <th scope='row' className='t-gray'>
                <FcCellPhone className='s-icon' /> {t('mechanic.tel')}
              </th>
              <td>
                {isAuthenticated && edit ? (
                  <FormGroup>
                    <CustomInput type='number' placeholder='' name='tel' value={inputs.tel} onChange={onInputsChange} />
                  </FormGroup>
                ) : (
                  user?.tel
                )}
              </td>
            </tr>

            <tr>
              <th scope='row' className='t-gray'>
                <FcCellPhone className='s-icon' /> {t('mechanic.tel')} 2
              </th>
              <td>
                {isAuthenticated && edit ? (
                  <FormGroup>
                    <CustomInput
                      type='number'
                      placeholder=''
                      name='tel2'
                      value={inputs.tel2}
                      onChange={onInputsChange}
                    />
                  </FormGroup>
                ) : (
                  user?.tel2
                )}
              </td>
            </tr>

            <tr>
              <th scope='row' className='t-gray'>
                <FcCellPhone className='s-icon' /> {t('mechanic.tel')} 3
              </th>
              <td>
                {isAuthenticated && edit ? (
                  <FormGroup>
                    <CustomInput
                      type='number'
                      placeholder=''
                      name='tel3'
                      value={inputs.tel3}
                      onChange={onInputsChange}
                    />
                  </FormGroup>
                ) : (
                  user?.tel3
                )}
              </td>
            </tr>

            <tr>
              <th scope='row' className='t-gray'>
                <FcExternal className='s-icon' /> {t('mechanic.website')}
              </th>
              <td>
                {isAuthenticated && edit ? (
                  <FormGroup>
                    <CustomInput
                      type='url'
                      placeholder=''
                      name='website'
                      value={inputs.website}
                      onChange={onInputsChange}
                    />
                  </FormGroup>
                ) : (
                  user?.website
                )}
              </td>
            </tr>

            {/* {isAuthenticated && (
              <tr>
                <th scope='row' className='t-gray'>
                  <FcInvite className='s-icon' /> {t('mechanic.showEmail')}
                </th>
                <td>
                  {edit ? (
                    <FormGroup>
                      <CustomInput
                        type='checkbox'
                        name='showEmail'
                        checked={inputs.showEmail}
                        onChange={e => setInputs(c => ({ ...c, showEmail: e.target.checked }))}
                      />
                    </FormGroup>
                  ) : user?.showEmail ? (
                    t('general.yes')
                  ) : (
                    t('general.no')
                  )}
                </td>
              </tr>
            )} */}
          </tbody>
        </Table>
      </Col>
    </>
  );
};

export default Contact;
