///////////////////////////////////////////////////////////////////////////////////MODULES
import axios from 'axios';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveRecaptchaSiteSecret, resolveHost } from './resolveEnv';
//////////////////////////////////////////////////////////////////////////////////////////

const verifyRecaptcha = async recaptchaRes => {
  try {
    const res = await axios.post(`${resolveHost()}/api/verify-recaptcha`, {
      recaptchaRes,
      secret: resolveRecaptchaSiteSecret(),
    });

    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    console.error(e);
    return false;
  }
};

export default verifyRecaptcha;
