///////////////////////////////////////////////////////////////////////////////////MODULES
import {
  Col,
  CustomButton,
  CustomInput,
  FormGroup,
  Label,
  PopoverBody,
  Row,
  UncontrolledPopover,
} from '@ibiliaze/reactstrap';
import { useTranslation } from 'react-i18next';
//////////////////////////////////////////////////////////////////////////////////////////

const MechanicsHeader = ({ inputs, onInputsChange }) => {
  // Translation
  const [t] = useTranslation('global');
  const sortOptions = t('options.sortOptions', { returnObjects: true });
  const searchByOptions = t('options.searchByOptions', { returnObjects: true });
  const accStatusOptions = t('options.accStatusOptions', { returnObjects: true });

  // JSX
  return (
    <>
      <UncontrolledPopover placement='bottom' target='filter-toggler' trigger='legacy'>
        <PopoverBody>
          <FormGroup floating>
            <CustomInput type='select' placeholder='' name='status' value={inputs.status} onChange={onInputsChange}>
              <option value={t('options.all.value')}>{t('options.all.label')}</option>
              {accStatusOptions.map((o, i) => (
                <option key={i} value={o.value}>
                  {o.label}
                </option>
              ))}
            </CustomInput>
            <Label>{t('mechanic.status')}</Label>
          </FormGroup>
        </PopoverBody>
      </UncontrolledPopover>

      <Row>
        <Col md={3}>
          <FormGroup>
            <CustomButton id='filter-toggler' style={{ width: '100%' }}>
              {t('buttons.filters')}
            </CustomButton>
          </FormGroup>
        </Col>
        <Col md={9}>
          <FormGroup floating>
            <CustomInput type='select' name='sortBy' value={inputs.sortBy} onChange={onInputsChange}>
              <option value={t('options.select.value')}>{t('options.select.label')}</option>
              {sortOptions.map((o, i) => (
                <option key={i} value={o.value}>
                  {o.label}
                </option>
              ))}
            </CustomInput>
            <Label>{t('inputs.sort.label')}</Label>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup floating>
            <CustomInput type='select' name='searchBy' placeholder='' value={inputs.searchBy} onChange={onInputsChange}>
              <option value={t('options.select.value')}>{t('options.select.label')}</option>
              {searchByOptions.map((o, i) => (
                <option key={i} value={o.value}>
                  {o.label}
                </option>
              ))}
            </CustomInput>
            <Label>{t('inputs.searchBy.label')}</Label>
          </FormGroup>
        </Col>
        {inputs.searchBy === 'Name' ? (
          <Col md={9}>
            <FormGroup floating>
              <CustomInput name='businessName' placeholder='' value={inputs.businessName} onChange={onInputsChange} />
              <Label>{t('inputs.searchByName.label')}</Label>
            </FormGroup>
          </Col>
        ) : (
          <Col md={9}>
            <FormGroup floating>
              <CustomInput name='mechanicId' placeholder='' value={inputs.mechanicId} onChange={onInputsChange} />
              <Label>{t('inputs.searchById.label')}</Label>
            </FormGroup>
          </Col>
        )}
      </Row>
    </>
  );
};

export default MechanicsHeader;
