///////////////////////////////////////////////////////////////////////////////////MODULES
import { Table } from '@ibiliaze/reactstrap';
import { useTranslation } from 'react-i18next';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const ReviewInputsTable = ({ review }) => {
  // Translation
  const [t] = useTranslation('global');

  // JSX
  return (
    <Table borderless>
      <tbody>
        <tr>
          <th scope='row' className='t-f-s-l'>
            {t('review.clientDetails')}
          </th>
          <td />
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t('inputs.email.label')}
          </th>
          <td className='t-align-r'>{review.email}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t('mechanic.tel')}
          </th>
          <td className='t-align-r'>{review.tel}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t('mechanic.name')}
          </th>
          <td className='t-align-r'>{review.name}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            IP
          </th>
          <td className='t-align-r'>{review.ip}</td>
        </tr>

        <tr>
          <th scope='row' className='t-f-s-l'>
            {t('review.reviewDetails')}
          </th>
          <td />
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t(c.profession)} ID
          </th>
          <td className='t-align-r'>{review.mechanicId}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t('general.skill')}
          </th>
          <td className='t-align-r'>{review.job}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t('mechanic.description')}
          </th>
          <td className='t-align-r'>{review.body}</td>
        </tr>

        <tr>
          <th scope='row' className='t-f-s-l'>
            {t('review.rating')}
          </th>
          <td />
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t('ratings.qualityOfWork')}
          </th>
          <td className='t-align-r'>{review.qualityOfWork}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t('ratings.reliability')}
          </th>
          <td className='t-align-r'>{review.reliability}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t('ratings.tidiness')}
          </th>
          <td className='t-align-r'>{review.tidiness}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t('ratings.communication')}
          </th>
          <td className='t-align-r'>{review.communication}</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default ReviewInputsTable;
