///////////////////////////////////////////////////////////////////////////////////MODULES
import { Col, CustomInput, FormGroup, Label, Row } from '@ibiliaze/reactstrap';
import { useTranslation } from 'react-i18next';
//////////////////////////////////////////////////////////////////////////////////////////

const LogsHeader = ({ inputs, onInputsChange }) => {
  // Translation
  const [t] = useTranslation('global');
  const sortOptions = t('options.sortOptions', { returnObjects: true });

  // JSX
  return (
    <Row>
      <Col md={12}>
        <FormGroup floating>
          <CustomInput type='select' name='sortBy' value={inputs.sortBy} onChange={onInputsChange}>
            <option value={t('options.select.value')}>{t('options.select.label')}</option>
            {sortOptions.map((o, i) => (
              <option key={i} value={o.value}>
                {o.label}
              </option>
            ))}
          </CustomInput>
          <Label>{t('inputs.sort.label')}</Label>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default LogsHeader;
