///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from 'react';
import { connect } from 'react-redux';
import {
  Col,
  Form,
  CustomInput,
  FormGroup,
  FormText,
  Label,
  MainImage,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from '@ibiliaze/reactstrap';
import { FadeIn } from '@ibiliaze/react-intersect';
import { FullPage, Section } from '@ibiliaze/react-base';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { confirmEmail } from '../../../actions/email';
import { postComplaint } from '../../../actions/complaints';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../../utils/constants';
import { resolveRecaptchaSiteKey } from '../../../utils/resolveEnv';
import verifyRecaptcha from '../../../utils/verifyRecaptcha';
//////////////////////////////////////////////////////////////////////////////////////////

const MakeAComplaintPage = ({ confirmEmail, postComplaint }) => {
  // State
  const [modal, setModal] = useState(false);
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [inputs, setInputs] = useState({
    mechanicId: '',
    concern: '',
    body: '',
    email: '',
    name: '',
    tel: '',
    code: '',
  });

  // Translation
  const [t] = useTranslation('global');

  // Toggle functions
  const toggle = _ => setModal(!modal);

  // onChange functions
  const onInputsChange = e => setInputs(c => ({ ...c, [e.target.name]: e.target.value }));
  const onRecaptchaChange = async value => setRecaptchaVerified(await verifyRecaptcha(value));

  // onClick functions
  const onPostComplaintlick = async _ => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      await postComplaint({ ...inputs, ip: data.ip });
      toggle();
    } catch (e) {
      console.error(e);
    }
  };

  // onSubmit functions
  const onSubmit = async e => {
    e.preventDefault();
    try {
      await confirmEmail({ email: inputs.email?.toLowerCase() });
      setModal(true);
    } catch (e) {
      console.error(e);
    }
  };

  //JSX
  return (
    <FullPage>
      <FadeIn>
        <MainImage
          mobileWidth={c.mainPic.mobileWidth}
          formGroup={false}
          height='300px'
          mobileHeight='300px'
          // Img
          imgPath='/img/complaint.jpg'
          // Title
          title={t('headers.complaint')}
        />
      </FadeIn>
      <Section className='custom-page p-b-m p-t-l'>
        <h2 className='t-align-c'>{t('complaintPage.waysToResolve')}</h2>
        <br />
        <Row>
          <Col md={6}>
            <h4 className='t-align-c'>{t('complaintPage.contactMechanic')}</h4>
            <p>{t('complaintPage.p1')}</p>
            <p>{t('complaintPage.p2')}</p>
            <p>{t('complaintPage.p3')}:</p>
            <p>{t('complaintPage.b1')}</p>
            <p>{t('complaintPage.b2')}</p>
            <p>{t('complaintPage.b3')}</p>
            <p>{t('complaintPage.p4')}</p>
            <p>{t('complaintPage.p5')}</p>
            <p>{t('complaintPage.p6')}</p>
          </Col>
          <Col md={6}>
            <h4 className='t-align-c'>{t('complaintPage.contactUs')}</h4>
            <Form onSubmit={onSubmit}>
              <FormGroup floating>
                <CustomInput
                  required
                  placeholder=''
                  name='mechanicId'
                  value={inputs.mechanicId}
                  onChange={onInputsChange}
                />
                <Label>{t('inputs.mechanicId.label')} *</Label>
                <FormText>
                  Do you know their Mechanic ID? You can find this information under their "Company Info" section on
                  their profile.
                </FormText>
              </FormGroup>

              <FormGroup floating>
                <CustomInput required placeholder='' name='concern' value={inputs.concern} onChange={onInputsChange} />
                <Label>{t('inputs.concern.label')} *</Label>
                <FormText>{t('inputs.concern.formtext')}</FormText>
              </FormGroup>

              <FormGroup>
                <Label>{t('mechanic.description')} *</Label>
                <CustomInput
                  required
                  placeholder={t('inputs.description.ph1')}
                  name='body'
                  value={inputs.body}
                  onChange={onInputsChange}
                  type='textarea'
                  rows='4'
                />
                <FormText>{t('inputs.description.formtext1')}</FormText>
              </FormGroup>

              <FormGroup floating>
                <CustomInput required placeholder='' name='name' value={inputs.name} onChange={onInputsChange} />
                <Label>{t('inputs.name.label')} *</Label>
              </FormGroup>

              <FormGroup floating>
                <CustomInput required placeholder='' name='email' value={inputs.email} onChange={onInputsChange} />
                <Label>{t('inputs.email.label')} *</Label>
              </FormGroup>

              <FormGroup floating>
                <CustomInput required placeholder='' name='tel' value={inputs.tel} onChange={onInputsChange} />
                <Label>{t('mechanic.tel')} *</Label>
              </FormGroup>

              <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>One Time Passcode</ModalHeader>
                <ModalBody>
                  <FormGroup floating>
                    <CustomInput
                      required
                      placeholder=''
                      type='number'
                      name='code'
                      value={inputs.code}
                      onChange={onInputsChange}
                    />
                    <Label>{t('inputs.emailOtp.label')} *</Label>
                    <FormText>{t('inputs.emailOtp.formtext')}</FormText>
                  </FormGroup>
                </ModalBody>
                <ModalFooter>
                  <ReCAPTCHA sitekey={resolveRecaptchaSiteKey()} onChange={onRecaptchaChange} className='m-auto' />
                  <Button disabled={!recaptchaVerified} onClick={onPostComplaintlick} color='primary' className='w-100'>
                    {t('buttons.continue')}
                  </Button>
                </ModalFooter>
              </Modal>
              <Button type='submit' color='primary'>
                {t('buttons.continue')}
              </Button>
            </Form>
          </Col>
        </Row>
      </Section>
    </FullPage>
  );
};

export default connect(null, { confirmEmail, postComplaint })(MakeAComplaintPage);
