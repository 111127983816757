///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row, CustomCard } from '@ibiliaze/reactstrap';
import { useTranslation } from 'react-i18next';
import { TransformYDown, FadeIn } from '@ibiliaze/react-intersect';
import { Section } from '@ibiliaze/react-base';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import QuoteModal from '../Profile/QuoteModal';
//////////////////////////////////////////////////////////////////////////////////////////

const Copy = () => {
  // State
  const [quoteModal, setQuoteModal] = useState(false);

  // Translation
  const [t] = useTranslation('global');

  // Toggle functions
  const toggleQuoteModal = _ => setQuoteModal(!quoteModal);

  // Navigate
  const navigate = useNavigate();

  // JSX
  return (
    <Section className='custom-page p-t-m p-b-m'>
      <FadeIn>
        <h1 className='t-align-c'>
          {t('headers.copy')} <span className='text-gradient'>{t('headers.copyHighlight')}</span>
        </h1>
      </FadeIn>

      <Row>
        <Col md={4}>
          <TransformYDown>
            <CustomCard
              // imgSrc='/img/plumber.jpg'
              multiclick={false}
              title={t('home.redirects.1')}
              titleTag='h6'
              subtitleTag='h6'
              text={t('home.redirects.1d')}
              clickedTitle={'Added - '}
              onCardClickHandler={toggleQuoteModal}
              onCardSecondClickHandler={_ => {}}
              // args={p}
            />
          </TransformYDown>
        </Col>

        <Col md={4}>
          <TransformYDown>
            <CustomCard
              // imgSrc='/img/plumber.jpg'
              multiclick={false}
              title={t('home.redirects.2')}
              titleTag='h6'
              subtitleTag='h6'
              text={t('home.redirects.2d')}
              clickedTitle={'Added - '}
              onCardClickHandler={_ => navigate('/why-us')}
              onCardSecondClickHandler={_ => {}}
              // args={p}
            />
          </TransformYDown>
        </Col>

        <Col md={4}>
          <TransformYDown>
            <CustomCard
              // imgSrc='/img/create-acc.jpg'
              multiclick={false}
              title={t('home.redirects.3')}
              titleTag='h6'
              subtitleTag='h6'
              text={t('home.redirects.3d')}
              clickedTitle={'Added - '}
              onCardClickHandler={_ => navigate('/auth')}
              onCardSecondClickHandler={_ => {}}
              // args={p}
            />
          </TransformYDown>
        </Col>
      </Row>

      <QuoteModal mechanicId={null} modal={quoteModal} toggle={toggleQuoteModal} />
    </Section>
  );
};

export default Copy;
