///////////////////////////////////////////////////////////////////////////////////MODULES
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Offcanvas, OffcanvasBody, OffcanvasHeader, Spinner, CustomPagination } from '@ibiliaze/reactstrap';
import { useTranslation } from 'react-i18next';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { getLeads } from '../../../actions/leads';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import LeadInputsTable from './LeadInputsTable';
import LeadsHeader from './LeadsHeader';
import LeadsList from './LeadsList';
//////////////////////////////////////////////////////////////////////////////////////////

const Leads = ({ leads, getLeads }) => {
  // State
  const [loading, setLoading] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [page, setPage] = useState(1);
  const [inputs, setInputs] = useState({
    mechanicId: '',
    reviewed: '',
    sortBy: '',
  });
  const [lead, setLead] = useState(null);

  // Toggle functions
  const toggle = _ => setCanvas(!canvas);

  // Translation
  const [t] = useTranslation('global');

  // onChange functions
  const onInputsChange = e => setInputs(c => ({ ...c, [e.target.name]: e.target.value }));

  // onClick functions
  const onLeadClick = t => {
    toggle();
    setLead(t);
  };
  const onPageClick = (e, index) => {
    e.preventDefault();
    index < 1 ? setPage(1) : setPage(index);
  };

  // Lifecycle hooks
  useEffect(() => {
    const req = async _ => {
      try {
        setLoading(true);
        const mechanicIdEndpoint = !!inputs.mechanicId ? `&mechanicId=${inputs.mechanicId}` : '';
        const sortEndpoint = `&sortBy=${inputs.sortBy}`;
        const limitEndpoint = `&limit=20&skip=${20 * (page - 1)}`;

        await getLeads('?' + mechanicIdEndpoint + limitEndpoint + sortEndpoint);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };
    req();
  }, [page, inputs]);

  // JSX
  return (
    <>
      <LeadsHeader inputs={inputs} onInputsChange={onInputsChange} />

      <CustomPagination page={page} onPageClick={onPageClick} />

      {loading ? <Spinner color='primary' /> : <LeadsList leads={leads} onLeadClick={onLeadClick} />}

      {!!lead && typeof lead === 'object' && (
        <Offcanvas direction='start' fade scrollable isOpen={canvas} toggle={toggle}>
          <OffcanvasHeader toggle={toggle}>{t('lead.leadManager')}</OffcanvasHeader>
          <OffcanvasBody>
            <LeadInputsTable lead={lead} />
          </OffcanvasBody>
        </Offcanvas>
      )}
    </>
  );
};

const mapStateToProps = state => ({ leads: state.leads });

export default connect(mapStateToProps, { getLeads })(Leads);
