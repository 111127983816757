///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, CustomInput, FormGroup, InputGroup, Spinner, MainImage, Row } from '@ibiliaze/reactstrap';
import { FullPage, Section } from '@ibiliaze/react-base';
import { FadeIn } from '@ibiliaze/react-intersect';
import { useTranslation } from 'react-i18next';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert, clearMessages } from '../../../actions/alert';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../../utils/constants';
import apiRequest from '../../../utils/httpRequest';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import ReviewModal from '../../Profile/Reviews/ReviewModal';
//////////////////////////////////////////////////////////////////////////////////////////

const AmendMyReview = ({ setAlert, clearMessages }) => {
  const [inputs, setInputs] = useState({ reviewId: '' });
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [review, setReview] = useState(null);

  // Translation
  const [t] = useTranslation('global');

  // Toggle functions
  const toggle = _ => setModal(!modal);

  // onChange functions
  const onInputsChange = e => setInputs(c => ({ ...c, [e.target.name]: e.target.value }));

  // onClick functions
  const onSearchClick = async _ => {
    try {
      setReview(null);
      clearMessages();
      setLoading(true);

      if (!inputs.reviewId) throw new Error('Specify a Review ID');

      const res = await apiRequest({ endpoint: `review/${inputs.reviewId}`, method: 'GET' });
      if (res.isError) throw new Error(res.message);
      if (Array.isArray(res) && res.length > 1) throw new Error('Specify a Review ID');
      if (typeof res !== 'object' || !res.hasOwnProperty('_id')) throw new Error('Specify a valid Review ID');

      setReview(res);
      setLoading(false);
      toggle();
    } catch (e) {
      setReview(null);
      setAlert(e?.message, true, null, null, false);
      setLoading(false);
    }
  };

  //JSX
  return (
    <FullPage>
      <FadeIn>
        <MainImage
          mobileWidth={c.mainPic.mobileWidth}
          formGroup={false}
          height='300px'
          mobileHeight='300px'
          // Img
          imgPath='/img/whyus.jpg'
          // Title
          title={t('headers.amendYourReview')}
          // Slogan
          slogan={t('headers.amendYourReview2')}
        />
      </FadeIn>
      <Section className='custom-page p-b-m p-t-l'>
        <Row>
          <Col md={5}>
            <p>{t('amendReviewPage.p1')}</p>
            <p>{t('amendReviewPage.p2')}</p>
            <p>{t('amendReviewPage.p3')}</p>
          </Col>
          <Col md={7}>
            <FormGroup floating>
              <InputGroup>
                <CustomInput
                  value={inputs.reviewId}
                  onChange={onInputsChange}
                  name='reviewId'
                  placeholder={t('inputs.reviewId.label')}
                />
                <Button color='primary' onClick={onSearchClick}>
                  {loading ? <Spinner type='grow' size={c.b.s} /> : t('buttons.findReview')}
                </Button>
              </InputGroup>
            </FormGroup>

            <ReviewModal review={review} modal={modal} toggle={toggle} />
          </Col>
        </Row>
      </Section>
    </FullPage>
  );
};

export default connect(null, { setAlert, clearMessages })(AmendMyReview);
