///////////////////////////////////////////////////////////////////////////////////MODULES
import { AccordionBody, AccordionHeader, AccordionItem, MainImage, UncontrolledAccordion } from '@ibiliaze/reactstrap';
import { FullPage, Section } from '@ibiliaze/react-base';
import { FadeIn } from '@ibiliaze/react-intersect';
import { useTranslation } from 'react-i18next';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const FAQPage = () => {
  // Translation
  const [t] = useTranslation('global');

  //JSX
  return (
    <FullPage>
      <FadeIn>
        <MainImage
          mobileWidth={c.mainPic.mobileWidth}
          formGroup={false}
          height='300px'
          mobileHeight='300px'
          // Img
          imgPath='/img/faq.jpg'
          // Title
          title={t('headers.faq')}
        />
      </FadeIn>
      <Section className='custom-page p-b-m p-t-l'>
        <UncontrolledAccordion defaultOpen='1'>
          {t('faqs', { returnObjects: true }).map((item, i) => (
            <AccordionItem key={i} md={6}>
              <AccordionHeader targetId={i}>
                <h4>{item.header.replace('{{ cust }}', c.cust)}</h4>
              </AccordionHeader>
              <AccordionBody accordionId={i}>{item.body.replace('{{ cust }}', c.cust)}</AccordionBody>
            </AccordionItem>
          ))}
        </UncontrolledAccordion>
      </Section>
    </FullPage>
  );
};

export default FAQPage;
