///////////////////////////////////////////////////////////////////////////////////MODULES
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Col,
  CustomInput,
  FormGroup,
  Spinner,
  Label,
  Row,
  TextButton,
  UncontrolledTooltip,
  Button,
  CustomButton,
  UncontrolledPopover,
  PopoverBody,
  CustomPagination,
} from '@ibiliaze/reactstrap';
import { useTranslation } from 'react-i18next';
import { FaCheck } from 'react-icons/fa';
import { FullPage } from '@ibiliaze/react-base';
/////////////////////////////////////////////////////////////////////////////////////HOOKS
import useWindowSize from '../../hooks/useWindowSize';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import apiRequest from '../../utils/httpRequest';
import { copyToClipboard } from '../../utils/copyToClipboard';
import c from '../../utils/constants';
import { Check } from '../../utils/icons';
//////////////////////////////////////////////////////////////////////////////////////////

const MechanicsPage = () => {
  // State
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(false);
  const [mechanics, setMechanics] = useState([]);
  const [page, setPage] = useState(1);
  const [inputs, setInputs] = useState({
    search: '',
    zip: '',
    mobile: 'All',
  });

  // Hooks
  const windowSize = useWindowSize();

  // Translation
  const [t] = useTranslation('global');
  const skills = t('skills', { returnObjects: true });
  const quoteTypeOptions = t('options.quoteTypeOptions', { returnObjects: true });
  const paymentTypeOptions = t('options.paymentTypeOptions', { returnObjects: true });

  // Location
  const { search: searchParam } = useLocation();

  // onChange functions
  const onInputsChange = e => setInputs(c => ({ ...c, [e.target.name]: e.target.value }));

  // onClick functions
  const onPageClick = (e, index) => {
    e.preventDefault();
    index < 1 ? setPage(1) : setPage(index);
  };
  const onCopyClick = mechanicId => {
    try {
      copyToClipboard(window.location.host + `/profile?user=${mechanicId}`);
    } catch (e) {
      console.error(e);
    }
  };

  // Lifecycle hooks
  useEffect(() => {
    const req = async _ => {
      try {
        setLoading(true);

        let totalUsers = [];

        // Url search
        const urlSkill = searchParam.replace('?skill=', '');
        setInputs(c => ({ ...c, search: inputs.search || urlSkill }));

        // Limit
        const limit = 10;
        const limitEndpoint = l => `&limit=${l}&skip=${l * (page - 1)}`;

        // Other endpoints
        const mobileEndpoint = inputs.mobile === 'All' ? '' : `&mobile=${inputs.mobile}`;
        const selects =
          'avatar mobile businessName callOut paymentTypes quoteType rating reviewCount status lat lng workRadius primarySkill secondarySkill tertiarySkill extraSkill';

        const req = async (e, l) => {
          try {
            const users = await apiRequest({
              endpoint: 'user/all',
              method: 'GET',
              params: `?status=Verified${mobileEndpoint}&clientZip=${inputs.zip}&${e}=${
                inputs.search || urlSkill
              }${l}&select=${selects}`,
            });

            return users;
          } catch (e) {
            return [];
          }
        };

        // Primary skill search
        totalUsers = await req('primarySkill', limitEndpoint(limit));

        // Secondary skill search
        if (totalUsers.length !== limit)
          totalUsers = [...totalUsers, ...(await req('secondarySkill', limitEndpoint(limit - totalUsers.length)))];

        // Tertiary Skill skill search
        if (totalUsers.length !== limit)
          totalUsers = [...totalUsers, ...(await req('tertiarySkill', limitEndpoint(limit - totalUsers.length)))];

        // Extra Skill skill search
        if (totalUsers.length !== limit)
          totalUsers = [...totalUsers, ...(await req('extraSkill', limitEndpoint(limit - totalUsers.length)))];

        setMechanics([...totalUsers]);
        setLoading(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    };
    req();
  }, [search, searchParam, page]);

  // JSX
  return (
    <FullPage className='below-header custom-page'>
      <UncontrolledPopover placement='bottom' target='filter-toggler' trigger='legacy'>
        <PopoverBody>
          <FormGroup floating>
            <CustomInput type='select' placeholder='' name='mobile' value={inputs.mobile} onChange={onInputsChange}>
              <option value={t('options.all.value')}>{t('options.all.label')}</option>
              <option value={true}>{t('general.yes')}</option>
              <option value={false}>{t('general.no')}</option>
            </CustomInput>
            <Label>{t('mechanic.mobile')}</Label>
          </FormGroup>
        </PopoverBody>
      </UncontrolledPopover>

      <Row>
        <Col md={3} />

        <Col md={2}>
          <FormGroup>
            <CustomButton id='filter-toggler' style={{ width: '100%' }}>
              {t('buttons.filters')}
            </CustomButton>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup floating>
            <CustomInput name='search' type='select' value={inputs.search} onChange={onInputsChange} placeholder=''>
              <option value={t('options.select.value')}>{t('options.select.label')}</option>
              {Object.keys(skills).map((key, i) => (
                <option key={i} value={key} label={skills[key]} />
              ))}
            </CustomInput>
            <Label>{t('inputs.searchSkill.label')}</Label>
          </FormGroup>
        </Col>
        <Col md={3} />
        <Col md={3} />
        <Col md={6}>
          <FormGroup floating>
            <CustomInput name='zip' value={inputs.zip} onChange={onInputsChange} placeholder='' />
            <Label>{t('inputs.whatsYourZip.label')}</Label>
          </FormGroup>
        </Col>
        <Col md={3} />
        <Col md={3} />
        <Col md={6}>
          <Button className='w-100' color='primary' onClick={_ => setSearch(!search)}>
            {t('buttons.search')}
          </Button>
        </Col>
        <Col md={3} />
      </Row>

      <hr />

      <CustomPagination page={page} onPageClick={onPageClick} />

      {loading ? (
        <Spinner color='primary' />
      ) : (
        mechanics.map((mechanic, i) => (
          <div key={i} className={`mechanic-list-item ${windowSize.width < c.mainPic.mobileWidth ? 't-align-c' : ''}`}>
            <Row>
              <Col md={2} className='t-align-l'>
                <img alt='avatar' src={mechanic?.avatar} className='mechanic-list-item-avatar' />
              </Col>
              <Col md={3} className='mechanic-list-info'>
                <h5 className='text-button' onClick={_ => window.open(`/profile?user=${mechanic?._id}`)}>
                  {mechanic?.businessName}
                </h5>

                {mechanic?.mobile && (
                  <div className='t-f-s-s t-gray'>
                    <FaCheck className='t-green' /> {t('mechanic.mobile')}
                  </div>
                )}

                <div className='t-f-s-s t-gray'>
                  <FaCheck className='t-green' /> {mechanic?.callOut} {t('mechanic.callOut')}
                </div>
                <div className='t-f-s-s t-gray'>
                  <FaCheck className='t-green' />{' '}
                  {!!mechanic?.quoteType && quoteTypeOptions.find(o => o.value === mechanic?.quoteType)?.label}{' '}
                  {t('mechanic.quoteType')}
                </div>
                <div className='t-f-s-s t-gray'>
                  <FaCheck className='t-green' /> {t('general.accepts')}{' '}
                  {!!mechanic?.paymentTypes && paymentTypeOptions.find(o => o.value === mechanic?.paymentTypes)?.label}
                </div>
              </Col>
              <Col md={3} className='mechanic-list-info'>
                {windowSize.width < c.mainPic.mobileWidth && <hr />}

                <h5 className='t-green'>{skills[mechanic?.primarySkill]}</h5>
                <div className='t-gray t-f-s-s'>{skills[mechanic?.secondarySkill]}</div>
                <div className='t-gray t-f-s-s'>{skills[mechanic?.tertiarySkill]}</div>
                <div className='t-gray t-f-s-s'>{skills[mechanic?.extraSkill]}</div>
              </Col>
              <Col md={2} className='mechanic-list-info'>
                {windowSize.width < c.mainPic.mobileWidth && <hr />}

                <div>
                  <span className='text-gradient t-f-s-xxl'>{Math.floor(mechanic?.rating)}</span>/100
                </div>
                <div className='t-gray'>
                  <span>{Math.floor(mechanic?.reviewCount)}</span> {t('review.reviews')}
                </div>
                {mechanic?.status === 'Verified' && (
                  <div className='t-green'>
                    <Check size='s' />
                    {t('general.verified')} {t('general.profession')}
                  </div>
                )}
              </Col>
              <Col md={2} className='mechanic-list-info'>
                {windowSize.width < c.mainPic.mobileWidth && <hr />}

                <TextButton onClick={_ => window.open(`/profile?user=${mechanic?._id}`)}>
                  {t('buttons.viewAccount')}
                </TextButton>
                <br />
                <TextButton onClick={_ => onCopyClick(mechanic?._id)} id='copy-share-link2'>
                  {t('buttons.copyLink')}
                </TextButton>
                <UncontrolledTooltip trigger='click' target='copy-share-link2'>
                  {t('general.copied')}
                </UncontrolledTooltip>
              </Col>
            </Row>
          </div>
        ))
      )}
    </FullPage>
  );
};

export default MechanicsPage;
