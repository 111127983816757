///////////////////////////////////////////////////////////////////////////////////MODULES
import {
  Col,
  CustomButton,
  CustomInput,
  FormGroup,
  Label,
  PopoverBody,
  Row,
  UncontrolledPopover,
} from '@ibiliaze/reactstrap';
import { useTranslation } from 'react-i18next';
//////////////////////////////////////////////////////////////////////////////////////////

const ReviewsHeader = ({ inputs, onInputsChange }) => {
  // Translation
  const [t] = useTranslation('global');
  const sortOptions = t('options.sortOptions', { returnObjects: true });
  const reviewStatusOptions = t('options.reviewStatusOptions', { returnObjects: true });

  // JSX
  return (
    <>
      <UncontrolledPopover placement='bottom' target='filter-toggler' trigger='legacy'>
        <PopoverBody>
          <FormGroup floating>
            <CustomInput type='select' placeholder='' name='verified' value={inputs.verified} onChange={onInputsChange}>
              <option value={t('options.all.value')}>{t('options.all.label')}</option>
              {reviewStatusOptions.map((o, i) => (
                <option key={i} value={o.value}>
                  {o.label}
                </option>
              ))}
            </CustomInput>
            <Label>{t('inputs.reviewStatus.label')}</Label>
          </FormGroup>
        </PopoverBody>
      </UncontrolledPopover>

      <Row>
        <Col md={2}>
          <FormGroup>
            <CustomButton id='filter-toggler' style={{ width: '100%' }}>
              {t('buttons.filters')}
            </CustomButton>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup floating>
            <CustomInput type='select' name='sortBy' value={inputs.sortBy} onChange={onInputsChange}>
              <option value={t('options.select.value')}>{t('options.select.label')}</option>
              {sortOptions.map((o, i) => (
                <option key={i} value={o.value}>
                  {o.label}
                </option>
              ))}
            </CustomInput>
            <Label>{t('inputs.sort.label')}</Label>
          </FormGroup>
        </Col>
        <Col md={7}>
          <FormGroup floating>
            <CustomInput name='mechanicId' placeholder='' value={inputs.mechanicId} onChange={onInputsChange} />
            <Label>{t('inputs.mechanicIdSearch.label')}</Label>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default ReviewsHeader;
