////////////////////////////////////////////////////////////////////////////////USER TYPES
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const PUT_USER_SUCCESS = 'PUT_USER_SUCCESS';
export const PUT_USER_FAILURE = 'PUT_USER_FAILURE';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
///////////////////////////////////////////////////////////////////////////////ADMIN TYPES
export const LOGIN_ADMIN_SUCCESS = 'LOGIN_ADMIN_SUCCESS';
export const LOGIN_ADMIN_FAILURE = 'LOGIN_ADMIN_FAILURE';
export const REGISTER_ADMIN_SUCCESS = 'REGISTER_ADMIN_SUCCESS';
export const REGISTER_ADMIN_FAILURE = 'REGISTER_ADMIN_FAILURE';
export const GET_ADMIN_SUCCESS = 'GET_ADMIN_SUCCESS';
export const GET_ADMIN_FAILURE = 'GET_ADMIN_FAILURE';
export const LOGOUT_ADMIN_SUCCESS = 'LOGOUT_ADMIN_SUCCESS';
export const LOGOUT_ADMIN_FAILURE = 'LOGOUT_ADMIN_FAILURE';
export const PUT_ADMIN_SUCCESS = 'PUT_ADMIN_SUCCESS';
export const PUT_ADMIN_FAILURE = 'PUT_ADMIN_FAILURE';
export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS';
export const DELETE_ADMIN_FAILURE = 'DELETE_ADMIN_FAILURE';
///////////////////////////////////////////////////////////////////////////////EMAIL TYPES
export const CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS';
export const CONFIRM_EMAIL_FAILURE = 'CONFIRM_EMAIL_FAILURE';
export const PUSH_EMAIL_SUCCESS = 'PUSH_EMAIL_SUCCESS';
export const PUSH_EMAIL_FAILURE = 'PUSH_EMAIL_FAILURE';
//////////////////////////////////////////////////////////////////////////////REVIEW TYPES
export const GET_REVIEWS_SUCCESS = 'GET_REVIEWS_SUCCESS';
export const GET_REVIEWS_FAILURE = 'GET_REVIEWS_FAILURE';
export const POST_REVIEW_SUCCESS = 'POST_REVIEW_SUCCESS';
export const POST_REVIEW_FAILURE = 'POST_REVIEW_FAILURE';
export const PUT_REVIEW_SUCCESS = 'PUT_REVIEW_SUCCESS';
export const PUT_REVIEW_FAILURE = 'PUT_REVIEW_FAILURE';
export const DELETE_REVIEW_SUCCESS = 'DELETE_REVIEW_SUCCESS';
export const DELETE_REVIEW_FAILURE = 'DELETE_REVIEW_FAILURE';
///////////////////////////////////////////////////////////////////////////COMPLAINT TYPES
export const GET_COMPLAINTS_SUCCESS = 'GET_COMPLAINTS_SUCCESS';
export const GET_COMPLAINTS_FAILURE = 'GET_COMPLAINTS_FAILURE';
export const POST_COMPLAINT_SUCCESS = 'POST_COMPLAINT_SUCCESS';
export const POST_COMPLAINT_FAILURE = 'POST_COMPLAINT_FAILURE';
export const PUT_COMPLAINT_SUCCESS = 'PUT_COMPLAINT_SUCCESS';
export const PUT_COMPLAINT_FAILURE = 'PUT_COMPLAINT_FAILURE';
export const DELETE_COMPLAINT_SUCCESS = 'DELETE_COMPLAINT_SUCCESS';
export const DELETE_COMPLAINT_FAILURE = 'DELETE_COMPLAINT_FAILURE';
/////////////////////////////////////////////////////////////////////////////////LOG TYPES
export const GET_LOGS_SUCCESS = 'GET_LOGS_SUCCESS';
export const GET_LOGS_FAILURE = 'GET_LOGS_FAILURE';
export const POST_LOG_SUCCESS = 'POST_LOG_SUCCESS';
export const POST_LOG_FAILURE = 'POST_LOG_FAILURE';
export const PUT_LOG_SUCCESS = 'PUT_LOG_SUCCESS';
export const PUT_LOG_FAILURE = 'PUT_LOG_FAILURE';
export const DELETE_LOG_SUCCESS = 'DELETE_LOG_SUCCESS';
export const DELETE_LOG_FAILURE = 'DELETE_LOG_FAILURE';
////////////////////////////////////////////////////////////////////////////////LEAD TYPES
export const GET_LEADS_SUCCESS = 'GET_LEADS_SUCCESS';
export const GET_LEADS_FAILURE = 'GET_LEADS_FAILURE';
export const POST_LEAD_SUCCESS = 'POST_LEAD_SUCCESS';
export const POST_LEAD_FAILURE = 'POST_LEAD_FAILURE';
export const PUT_LEAD_SUCCESS = 'PUT_LEAD_SUCCESS';
export const PUT_LEAD_FAILURE = 'PUT_LEAD_FAILURE';
export const DELETE_LEAD_SUCCESS = 'DELETE_LEAD_SUCCESS';
export const DELETE_LEAD_FAILURE = 'DELETE_LEAD_FAILURE';
////////////////////////////////////////////////////////////////////////////////ITEM TYPES
export const GET_ITEMS_SUCCESS = 'GET_ITEMS_SUCCESS';
export const GET_ITEMS_FAILURE = 'GET_ITEMS_FAILURE';
export const POST_ITEM_SUCCESS = 'POST_ITEM_SUCCESS';
export const POST_ITEM_FAILURE = 'POST_ITEM_FAILURE';
export const PUT_ITEM_SUCCESS = 'PUT_ITEM_SUCCESS';
export const PUT_ITEM_FAILURE = 'PUT_ITEM_FAILURE';
export const DELETE_ITEM_SUCCESS = 'DELETE_ITEM_SUCCESS';
export const DELETE_ITEM_FAILURE = 'DELETE_ITEM_FAILURE';
export const RESET_WISHLIST_SUCCESS = 'RESET_WISHLIST_SUCCESS';
export const RESET_WISHLIST_FAILURE = 'RESET_WISHLIST_FAILURE';
///////////////////////////////////////////////////////////////////////////////ALERT TYPES
export const GET_MESSAGES = 'GET_MESSAGES';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
////////////////////////////////////////////////////////////////////////////SETTIGNS TYPES
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const GET_SETTINGS_FAILURE = 'GET_SETTINGS_FAILURE';
export const PUT_SETTINGS_SUCCESS = 'PUT_SETTINGS_SUCCESS';
export const PUT_SETTINGS_FAILURE = 'PUT_SETTINGS_FAILURE';
export const RESET_SETTINGS_SUCCESS = 'RESET_SETTINGS_SUCCESS';
export const RESET_SETTINGS_FAILURE = 'RESET_SETTINGS_FAILURE';
//////////////////////////////////////////////////////////////////////////////////////////
