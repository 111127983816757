///////////////////////////////////////////////////////////////////////////////////MODULES
import { connect } from 'react-redux';
import { Footer as CustomFooter } from '@ibiliaze/reactstrap';
import { useTranslation } from 'react-i18next';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
import { resolveHost } from '../../utils/resolveEnv';
//////////////////////////////////////////////////////////////////////////////PACKAGE JSON
import packageJson from '../../../package.json';
//////////////////////////////////////////////////////////////////////////////////////////

const Footer = ({ settings }) => {
  // Translation
  const [t] = useTranslation('global');

  // JSX
  return (
    <CustomFooter
      title={c.cust}
      textButtons={[
        !!settings?.global?.tel
          ? {
              title: `📞 ${settings?.global?.tel}`,
              onClickHandler: _ => (window.location.href = `tel:${settings?.global?.tel}`),
            }
          : {},
        !!settings?.global?.tel2
          ? {
              title: `📞 ${settings?.global?.tel2}`,
              onClickHandler: _ => (window.location.href = `tel:${settings?.global?.tel2}`),
            }
          : {},
        !!settings?.global?.tel3
          ? {
              title: `📞 ${settings?.global?.tel3}`,
              onClickHandler: _ => (window.location.href = `tel:${settings?.global?.tel3}`),
            }
          : {},
        !!settings?.global?.email
          ? {
              title: `📧 ${settings?.global?.email}`,
              onClickHandler: _ => (window.location.href = `mailto:${settings?.global?.email}`),
            }
          : {},
        !!settings?.global?.email2
          ? {
              title: `📧 ${settings?.global?.email2}`,
              onClickHandler: _ => (window.location.href = `mailto:${settings?.global?.email2}`),
            }
          : {},
        !!settings?.global?.email3
          ? {
              title: `📧 ${settings?.global?.email3}`,
              onClickHandler: _ => (window.location.href = `mailto:${settings?.global?.email3}`),
            }
          : {},
        // {
        //   title: `📍 ${c.hqAddr}`,
        //   onClickHandler: _ => window.open(`https://www.google.com/maps/dir/?api=1&destination=${c.hqAddr}`),
        // },
      ]}
      navLinks={[
        {
          title: t('footer.faq'),
          href: resolveHost() + '/faq',
        },
        {
          title: t('footer.whyUs'),
          href: resolveHost() + '/why-us',
        },
        {
          title: t('footer.makeAComplaint'),
          href: resolveHost() + '/make-a-complaint',
        },
        {
          title: t('footer.amendMyReview'),
          href: resolveHost() + '/amend-my-review',
        },
        {
          title: t('footer.privacyPolicy'),
          href: resolveHost() + '/privacy-policy',
        },
        {
          title: t('footer.termsAndConditions'),
          href: resolveHost() + '/terms-conditions',
        },
        {
          title: t('footer.disclaimer'),
          href: resolveHost() + '/disclaimer',
        },
        {
          title: t('footer.refunds'),
          href: resolveHost() + '/return-policy',
        },
      ]}
      miniTexts={[`Version: ${packageJson.version}`]}
    />
  );
};

const mapStateToProps = state => ({ settings: state.settings });

export default connect(mapStateToProps)(Footer);
