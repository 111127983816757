///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FadeIn } from '@ibiliaze/react-intersect';
import { MainImage } from '@ibiliaze/reactstrap';
import { useTranslation } from 'react-i18next';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import About from '../Dashboard/About';
import Copy from '../Dashboard/Copy';
import Banner from '../Layout/Banner';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const HomePage = () => {
  // State
  const [search, setSearch] = useState('');

  // Translation
  const [t] = useTranslation('global');
  const skills = t('skills', { returnObjects: true });

  // Navigate
  const navigate = useNavigate();

  // JSX
  return (
    <>
      <FadeIn>
        <MainImage
          mobileWidth={c.mainPic.mobileWidth}
          formGroup={true}
          height={c.mainPic.height}
          mobileHeight={c.mainPic.mobileHeight}
          // Img
          imgPath={c.mainPic.path}
          // Title
          title={c.cust}
          // Slogan
          slogan={t(c.slogan)}
          // Button
          onButtonClick={_ => navigate(`/mechanics?skill=${search}`)}
          buttonText={t(c.mainPic.buttonText)}
          // Input
          inputPlaceHolder={t(c.mainPic.inputPlaceHolder)}
          inputOptions={[
            { value: t('options.select.value'), label: t('options.select.label') },
            ...Object.keys(skills).map(key => ({ value: key, label: skills[key] })),
          ]}
          inputValue={search}
          onInputChange={e => setSearch(e.target.value)}
          // Socials
          instagramHandle={c.socials.instagram}
        />
      </FadeIn>
      <About />
      <Banner />
      <Copy />
    </>
  );
};

export default HomePage;
