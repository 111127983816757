/////////////////////////////////////////////////////////////////////////////////////TYPES
import {
  PUT_SETTINGS_FAILURE,
  PUT_SETTINGS_SUCCESS,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAILURE,
  RESET_SETTINGS_SUCCESS,
  RESET_SETTINGS_FAILURE,
} from '../actions/types/types';
////////////////////////////////////////////////////////////////////SETTINGS DEFAULT STATE
const defaultSettings = {
  bannerHeader: '',
  bannerBody: '',
  bannerColour: '',
  bannerTextColour: '',
  showBanner: false,
  type: 'global',
};
const settingsDefaultState = {
  global: defaultSettings,
};
//////////////////////////////////////////////////////////////////////////SETTINGS REDUCER
const settings = (state = settingsDefaultState, action) => {
  switch (action.type) {
    // GET request
    case GET_SETTINGS_SUCCESS:
    case PUT_SETTINGS_SUCCESS:
    case RESET_SETTINGS_SUCCESS:
      return { ...state, global: { ...action.settings } };
    case PUT_SETTINGS_FAILURE:
    case GET_SETTINGS_FAILURE:
    case RESET_SETTINGS_FAILURE:
      return { ...state };

    default:
      return state;
  }
};

export default settings;
