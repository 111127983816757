///////////////////////////////////////////////////////////////////////////////////MODULES
import { Table } from '@ibiliaze/reactstrap';
import { useTranslation } from 'react-i18next';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const ComplaintInputsTable = ({ complaint }) => {
  // Translation
  const [t] = useTranslation('global');

  // JSX
  return (
    <Table borderless>
      <tbody>
        <tr>
          <th scope='row' className='t-f-s-l'>
            Client Details
          </th>
          <td />
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            Email
          </th>
          <td className='t-align-r'>{complaint.email}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            Phone
          </th>
          <td className='t-align-r'>{complaint.tel}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            Name
          </th>
          <td className='t-align-r'>{complaint.name}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            IP address
          </th>
          <td className='t-align-r'>{complaint.ip}</td>
        </tr>

        <tr>
          <th scope='row' className='t-f-s-l'>
            Complaint Details
          </th>
          <td />
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t(c.profession)} ID
          </th>
          <td className='t-align-r'>{complaint.mechanicId}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            Concern
          </th>
          <td className='t-align-r'>{complaint.concern}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            Description
          </th>
          <td className='t-align-r'>{complaint.body}</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default ComplaintInputsTable;
