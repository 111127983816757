///////////////////////////////////////////////////////////////////////////////////MODULES
import { BrowserRouter, Route, Routes } from 'react-router-dom';
/////////////////////////////////////////////////////////////////////////////////////PAGES
import HomePage from '../components/Pages/HomePage';
import AuthPage from '../components/Pages/AuthPage';
import ProfilePage from '../components/Pages/ProfilePage';
import AdminPage from '../components/Pages/AdminPage';
import MechanicsPage from '../components/Pages/MechanicsPage';
import FAQPage from '../components/Pages/footer/FAQPage';
import WhyUsPage from '../components/Pages/footer/WhyUsPage';
import MakeAComplaintPage from '../components/Pages/footer/MakeAComplaintPage';
import AmendMyReview from '../components/Pages/footer/AmendMyReview';
import PrivacyPolicyPage from '../components/Pages/legal/PrivacyPolicyPage';
import CookiesPolicyPage from '../components/Pages/legal/CookiesPolicyPage';
import TermsConditions from '../components/Pages/legal/TermsConditions';
import DisclaimerPage from '../components/Pages/legal/DisclaimerPage';
import ReturnPolicyPage from '../components/Pages/legal/ReturnPolicyPage';
import FailedPaymentPage from '../components/Pages/payment/FailedPaymentPage';
import SuccessPaymentPage from '../components/Pages/payment/SuccessPaymentPage';
import NotFoundPage from '../components/Pages/NotFoundPage';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Header from '../components/Layout/Header';
import Alert from '../components/Layout/Alert';
import Footer from '../components/Layout/Footer';
import ScrollUp from '../components/Layout/ScrollUp';
//////////////////////////////////////////////////////////////////////////////////////////

const AppRouter = () => (
  <BrowserRouter>
    <>
      <ScrollUp />
      <Header />
      <Alert />
      <Routes>
        <Route exact path='/' element={<HomePage />} />
        <Route exact path='/auth' element={<AuthPage />} />
        <Route exact path='/profile' element={<ProfilePage />} />
        <Route exact path='/admin' element={<AdminPage />} />
        <Route exact path='/mechanics' element={<MechanicsPage />} />
        {/* Footer Pages */}
        <Route exact path='/faq' element={<FAQPage />} />
        <Route exact path='/why-us' element={<WhyUsPage />} />
        <Route exact path='/make-a-complaint' element={<MakeAComplaintPage />} />
        <Route exact path='/amend-my-review' element={<AmendMyReview />} />
        {/* Payment Pages */}
        <Route exact path='/success-payment' element={<SuccessPaymentPage />} />
        <Route exact path='/failed-payment' element={<FailedPaymentPage />} />
        {/* Legal Pages */}
        <Route exact path='/privacy-policy' element={<PrivacyPolicyPage />} />
        <Route exact path='/cookie-policy' element={<CookiesPolicyPage />} />
        <Route exact path='/terms-conditions' element={<TermsConditions />} />
        <Route exact path='/disclaimer' element={<DisclaimerPage />} />
        <Route exact path='/return-policy' element={<ReturnPolicyPage />} />
        {/* 404 */}
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
      <Footer />
    </>
  </BrowserRouter>
);

export default AppRouter;
