///////////////////////////////////////////////////////////////////////////////////MODULES
import { FormGroup, CustomInput, TextButton } from '@ibiliaze/reactstrap';
//////////////////////////////////////////////////////////////////////////////////////////

const LegalButton = ({ inputs, setInputs }) => (
  <FormGroup>
    <br />
    <CustomInput
      required
      type='checkbox'
      checked={inputs.legalChecked}
      onChange={e => setInputs(c => ({ ...c, legalChecked: e.target.checked }))}
    />{' '}
    I have read and agree to the{' '}
    <TextButton
      onClick={_ => {
        window.scrollTo({ top: 0 });
        window.open('/privacy-policy');
      }}
    >
      Privacy Policy,
    </TextButton>{' '}
    <TextButton
      onClick={_ => {
        window.scrollTo({ top: 0 });
        window.open('/terms-conditions');
      }}
    >
      Terms & Conditions,
    </TextButton>{' '}
    <TextButton
      onClick={_ => {
        window.scrollTo({ top: 0 });
        window.open('/return-policy');
      }}
    >
      Refund Policy
    </TextButton>
  </FormGroup>
);

export default LegalButton;
