///////////////////////////////////////////////////////////////////////////////////MODULES
import { CustomListGroup, CustomListGroupItem, ListGroupItemHeading, ListGroupItemText } from '@ibiliaze/reactstrap';
//////////////////////////////////////////////////////////////////////////////////////////

const LeadsList = ({ leads, onLeadClick }) => (
  <CustomListGroup>
    {leads.map((r, i) => (
      <CustomListGroupItem key={i} onClick={_ => onLeadClick(r)}>
        <ListGroupItemHeading>{r.email}</ListGroupItemHeading>
        <ListGroupItemText className='r-gray t-f-s-ss'>{r.createdAt}</ListGroupItemText>
      </CustomListGroupItem>
    ))}
  </CustomListGroup>
);

export default LeadsList;
