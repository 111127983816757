///////////////////////////////////////////////////////////////////////////////////MODULES
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, CustomButton, CustomInput, Form, FormGroup, Label, Row, Spinner } from '@ibiliaze/reactstrap';
import { useTranslation } from 'react-i18next';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { putSettings } from '../../actions/settings';
//////////////////////////////////////////////////////////////////////////////////////////

const Banner = ({ settings, putSettings }) => {
  // State
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    bannerHeader: '',
    bannerBody: '',
    bannerColour: '',
    bannerTextColour: '',
    showBanner: false,
  });

  // Translation
  const [t] = useTranslation('global');

  // onChange functions
  const onInputsChange = e => setInputs(c => ({ ...c, [e.target.name]: e.target.value }));

  // Lifecycle hooks
  useEffect(() => {
    try {
      setInputs({ ...settings.global });
    } catch (e) {
      console.error(e);
    }
  }, [settings]);

  // onSubmit functions
  const onSubmit = async e => {
    e.preventDefault();
    try {
      setLoading(true);
      await putSettings({ ...inputs, type: undefined });
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  // JSX
  return loading ? (
    <Spinner color='primary' />
  ) : (
    <Form onSubmit={onSubmit}>
      <FormGroup floating>
        <CustomInput name='bannerHeader' placeholder='' value={inputs.bannerHeader} onChange={onInputsChange} />
        <Label>{t('inputs.header.label')}</Label>
      </FormGroup>

      <FormGroup floating>
        <CustomInput
          type='textarea'
          name='bannerBody'
          placeholder=''
          value={inputs.bannerBody}
          onChange={onInputsChange}
        />
        <Label>{t('inputs.body.label')}</Label>
      </FormGroup>

      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>{t('inputs.colour.label')}</Label>
            <CustomInput type='color' name='bannerColour' value={inputs.bannerColour} onChange={onInputsChange} />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>{t('inputs.textColour.label')}</Label>
            <CustomInput
              type='color'
              name='bannerTextColour'
              value={inputs.bannerTextColour}
              onChange={onInputsChange}
            />
          </FormGroup>
        </Col>
      </Row>

      <FormGroup>
        <CustomInput
          type='switch'
          checked={inputs.showBanner}
          onChange={e => setInputs(c => ({ ...c, showBanner: e.target.checked }))}
        />{' '}
        <Label>{t('inputs.showBanner.label')}</Label>
      </FormGroup>

      <FormGroup>
        <CustomButton type='submit' color='primary'>
          {t('buttons.save')}
        </CustomButton>
      </FormGroup>
    </Form>
  );
};

const mapStateToProps = state => ({ settings: state.settings });

export default connect(mapStateToProps, { putSettings })(Banner);
