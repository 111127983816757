///////////////////////////////////////////////////////////////////////////////////MODULES
import {
  Button,
  CustomInput,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  CustomListGroup,
  CustomListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Col,
  Row,
  CustomButton,
} from '@ibiliaze/reactstrap';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaTrash } from 'react-icons/fa';
//////////////////////////////////////////////////////////////////////////////////////////

const Accreditations = ({ user, isAuthenticated, putUser }) => {
  // State
  const [modal, setModal] = useState(false);
  const [accreditations, setAccreditations] = useState([]);
  const [inputs, setInputs] = useState({
    type: '',
    name: '',
    body: '',
    dateAttained: '',
  });

  // Translation
  const [t] = useTranslation('global');
  const accrTypeOptions = t('options.accrTypeOptions', { returnObjects: true });

  // Toggle functions
  const toggle = _ => setModal(!modal);

  // onChange functions
  const onInputsChange = e => setInputs(c => ({ ...c, [e.target.name]: e.target.value }));

  // onClick functions
  const onDeleteClick = async i => {
    try {
      const arr = accreditations;
      arr.splice(i, 1);

      await putUser({ accreditations: [...arr] });
    } catch (e) {
      console.error(e);
    }
  };

  // Lifecycle hooks
  useEffect(() => {
    try {
      setAccreditations(user?.accreditations || []);
    } catch (e) {
      console.error(e);
    }
  }, [user]);

  // onSubmit functions
  const onSubmit = async e => {
    e.preventDefault();

    try {
      toggle();

      await putUser({ accreditations: [...accreditations, inputs] });
    } catch (e) {
      console.error(e);
    }
  };

  // JSX
  return (
    <>
      {isAuthenticated && (
        <>
          <div className='t-align-c'>
            <Button color='primary' onClick={toggle}>
              {t('buttons.add')}
            </Button>
          </div>
          <br />
        </>
      )}

      <Modal isOpen={modal} toggle={toggle}>
        <Form onSubmit={onSubmit}>
          <ModalHeader toggle={toggle}>{t('general.addAccr')}</ModalHeader>
          <ModalBody>
            <FormGroup floating>
              <CustomInput required name='name' placeholder='' value={inputs.name} onChange={onInputsChange} />
              <Label>{t('inputs.name.label')} *</Label>
            </FormGroup>

            <FormGroup floating>
              <CustomInput
                required
                type='select'
                name='type'
                placeholder=''
                value={inputs.type}
                onChange={onInputsChange}
              >
                <option value={t('options.select.value')}>{t('options.select.label')}</option>
                {accrTypeOptions.map((o, i) => (
                  <option key={i} value={o.value}>
                    {o.label}
                  </option>
                ))}
              </CustomInput>
              <Label>{t('inputs.type.label')} *</Label>
            </FormGroup>

            <FormGroup floating>
              <CustomInput required name='body' placeholder='' value={inputs.body} onChange={onInputsChange} />
              <Label>{t('inputs.body.label')} *</Label>
            </FormGroup>

            <FormGroup floating>
              <CustomInput
                required
                type='date'
                name='dateAttained'
                placeholder=''
                value={inputs.dateAttained}
                onChange={onInputsChange}
              />
              <Label>{t('inputs.dateAttained.label')} *</Label>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color='primary' type='submit' className='w-100'>
              {t('buttons.add')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <CustomListGroup>
        {accreditations.map((acc, i) => (
          <CustomListGroupItem key={i}>
            <Row>
              <Col md={isAuthenticated ? 11 : 12}>
                <ListGroupItemHeading>{acc.name}</ListGroupItemHeading>
                <ListGroupItemText className='t-gray t-f-s-s'>
                  {acc.body} ({accrTypeOptions.find(o => o.value === acc.type)?.label})
                </ListGroupItemText>
                <ListGroupItemText className='t-gray t-f-s-s'>{acc.dateAttained}</ListGroupItemText>
              </Col>

              {isAuthenticated && (
                <Col md={1}>
                  <CustomButton outline color='danger' onClick={async _ => onDeleteClick(i)}>
                    <FaTrash />
                  </CustomButton>
                </Col>
              )}
            </Row>
          </CustomListGroupItem>
        ))}
      </CustomListGroup>
    </>
  );
};

export default Accreditations;
