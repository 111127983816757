/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from '../actions/types/types';
////////////////////////////////////////////////////////////////////////AUTH DEFAULT STATE
const userDefaultState = {
  isAuthenticated: false,
  user: null,
  users: [],
};
//////////////////////////////////////////////////////////////////////////////AUTH REDUCER
const user = (state = userDefaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    // Register
    case types.REGISTER_SUCCESS:
      return { ...state, user: null, isAuthenticated: false };

    // Login
    case types.LOGIN_SUCCESS:
      return { ...state, ...payload, isAuthenticated: true };

    // GET request
    case types.GET_USER_SUCCESS:
      return { ...state, ...payload, isAuthenticated: true };
    case types.GET_USERS_SUCCESS:
      return { ...state, users: Array.isArray(payload) ? [...payload] : [payload] };

    // PUT request
    case types.PUT_USER_SUCCESS:
      return {
        ...state,
        user: { ...payload.user },
        users: state.users.map(user => {
          if (user._id === payload.user._id) {
            return { ...user, ...payload.user };
          } else {
            return user;
          }
        }),
      };
    case types.PUT_USER_FAILURE:
      return { ...state };

    // Auth failure & logout & DELETE request
    case types.LOGIN_FAILURE:
    case types.REGISTER_FAILURE:
    case types.GET_USER_FAILURE:
    case types.GET_USERS_FAILURE:
    case types.LOGOUT_SUCCESS:
    case types.LOGOUT_FAILURE:
      return { ...state, user: null, isAuthenticated: false };

    case types.DELETE_USER_SUCCESS:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        users: state.users.filter(user => user._id !== payload.user._id),
      };

    case types.DELETE_USER_FAILURE:
      return { ...state };

    default:
      return state;
  }
};

export default user;
