///////////////////////////////////////////////////////////////////////////////////MODULES
import { useEffect, useState } from 'react';
import { Col, CustomInput, Row, Table, TextButton, UncontrolledTooltip } from '@ibiliaze/reactstrap';
import { useTranslation } from 'react-i18next';
import { FaCheck } from 'react-icons/fa';
import {
  FcGlobe,
  FcCalendar,
  FcLibrary,
  FcShop,
  FcBusinessman,
  FcDecision,
  FcLikePlaceholder,
  FcViewDetails,
  FcCellPhone,
  FcLike,
  FcLink,
  FcMoneyTransfer,
  FcExternal,
  FcAutomotive,
  FcGrid,
} from 'react-icons/fc';
import QRCode from 'qrcode.react';
/////////////////////////////////////////////////////////////////////////////////////HOOKS
import useWindowSize from '../../hooks/useWindowSize';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
import { copyToClipboard } from '../../utils/copyToClipboard';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import EditSaveButton from './EditSaveButton';
import ReviewModal from './Reviews/ReviewModal';
import QuoteModal from './QuoteModal';
//////////////////////////////////////////////////////////////////////////////////////////

const Details = ({ user, isAuthenticated, putUser }) => {
  // State
  const [qrText, setQrText] = useState(null);
  const [reviewModal, setReviewModal] = useState(false);
  const [quoteModal, setQuoteModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [inputs, setInputs] = useState({
    name: '',
    zip: '',
    mobile: '',
    vat: '',
    compType: '',
    insurer: '',
    insuranceExpiry: '',
  });

  // Hooks
  const windowSize = useWindowSize();

  // Translation
  const [t] = useTranslation('global');
  const compTypeOptions = t('options.compTypeOptions', { returnObjects: true });
  const accStatusOptions = t('options.accStatusOptions', { returnObjects: true });

  // Toggle functions
  const toggleReviewModal = _ => setReviewModal(!reviewModal);
  const toggleQuotewModal = _ => setQuoteModal(!quoteModal);

  // onChange functions
  const onInputsChange = e => setInputs(c => ({ ...c, [e.target.name]: e.target.value }));

  // onClick functions
  const onQrClick = _ => {
    try {
      const canvas = document.getElementById('qr-code-canvas'); // Get the canvas element
      const link = document.createElement('a');
      link.download = 'qrcode.png';
      link.href = canvas.toDataURL('image/png'); // Convert canvas to image data URL
      link.click();
    } catch (e) {
      console.error(e);
    }
  };

  const onCopyClick = _ => {
    try {
      copyToClipboard(window.location.host + window.location.pathname + `?user=${user?._id}`);
    } catch (e) {
      console.error(e);
    }
  };

  // Lifecycle hooks
  useEffect(() => {
    try {
      setQrText(window.location.host + window.location.pathname + `?user=${user?._id}`);
      setInputs({
        name: user?.name,
        vat: user?.vat,
        zip: user?.zip,
        mobile: user?.mobile,
        compType: user?.compType,
        insurer: user?.insurer,
        insuranceExpiry: user?.insuranceExpiry,
      });
    } catch (e) {
      setQrText('');
      console.error(e);
    }
  }, [user]);

  // JSX
  return (
    <div className={windowSize.width > c.mainPic.mobileWidth ? 'user-section detail-section' : 'user-section-mobile'}>
      <h4 className='t-align-c'>{t('profileSections.details')}</h4>

      {isAuthenticated && <EditSaveButton inputs={inputs} edit={edit} setEdit={setEdit} putUser={putUser} />}

      <p className='t-align-c text-gradient'>
        {user?.reviewCount} {t('review.verifiedReviews')}
      </p>

      <Table borderless className='t-f-s-s'>
        <tbody>
          <tr>
            <th scope='row' className='t-gray'>
              <FcBusinessman className='xs-icon' /> {t('admin.owner')}
            </th>
            <td className='t-align-r'>
              {isAuthenticated && edit ? (
                <CustomInput
                  name='name'
                  placeholder='Owner'
                  value={inputs.name}
                  onChange={onInputsChange}
                  bsSize='sm'
                />
              ) : (
                user?.name
              )}
            </td>
          </tr>
          <tr>
            <th scope='row' className='t-gray'>
              <FcViewDetails className='xs-icon' /> {t('general.memberSince')}
            </th>
            <td className='t-align-r'>{user?.createdAt?.split('-')[0]}</td>
          </tr>
          <tr>
            <th scope='row' className='t-gray'>
              <FcDecision className='xs-icon' /> {t('general.identity')}
            </th>
            <td className='t-align-r t-green'>
              {user?.status === 'Verified' && <FaCheck className='xs-icon' />}
              {!!user?.status && accStatusOptions.find(o => o.value === user?.status)?.label}
            </td>
          </tr>
          <tr>
            <th scope='row' className='t-gray'>
              <FcShop className='xs-icon' /> {t('mechanic.compType')}
            </th>
            <td className='t-align-r'>
              {isAuthenticated && edit ? (
                <CustomInput
                  type='select'
                  name='compType'
                  value={inputs.compType}
                  onChange={onInputsChange}
                  bsSize='sm'
                >
                  <option value={t('options.select.value')}>{t('options.select.label')}</option>
                  {compTypeOptions.map((o, i) => (
                    <option key={i} value={o.value}>
                      {o.label}
                    </option>
                  ))}
                </CustomInput>
              ) : (
                !!user?.compType && compTypeOptions.find(o => o.value === user?.compType)?.label
              )}
            </td>
          </tr>
          <tr>
            <th scope='row' className='t-gray'>
              <FcLibrary className='xs-icon' /> {t('mechanic.vat')}
            </th>
            <td className='t-align-r'>
              {isAuthenticated && edit ? (
                <CustomInput
                  name='vat'
                  placeholder='VAT Number'
                  value={inputs.vat}
                  onChange={onInputsChange}
                  bsSize='sm'
                />
              ) : (
                user?.vat
              )}
            </td>
          </tr>
          <tr>
            <th scope='row' className='t-gray'>
              <FcLikePlaceholder className='xs-icon' /> {t('mechanic.insurer')}
            </th>
            <td className='t-align-r'>
              {isAuthenticated && edit ? (
                <CustomInput
                  name='insurer'
                  placeholder='Insurer'
                  value={inputs.insurer}
                  onChange={onInputsChange}
                  bsSize='sm'
                />
              ) : (
                user?.insurer
              )}
            </td>
          </tr>
          <tr>
            <th scope='row' className='t-gray'>
              <FcCalendar className='xs-icon' /> {t('mechanic.insuranceExpiry')}
            </th>
            <td className='t-align-r'>
              {isAuthenticated && edit ? (
                <CustomInput
                  type='date'
                  name='insuranceExpiry'
                  placeholder='Insurance Expiry'
                  value={inputs.insuranceExpiry}
                  onChange={onInputsChange}
                  bsSize='sm'
                />
              ) : (
                user?.insuranceExpiry
              )}
            </td>
          </tr>
          <tr>
            <th scope='row' className='t-gray'>
              <FcAutomotive className='xs-icon' /> {t('mechanic.mobile')}
            </th>
            <td className='t-align-r'>
              {edit ? (
                <CustomInput
                  type='checkbox'
                  name='mobile'
                  checked={inputs.mobile}
                  onChange={e => setInputs(c => ({ ...c, mobile: e.target.checked }))}
                />
              ) : user?.mobile ? (
                t('general.yes')
              ) : (
                t('general.no')
              )}
            </td>
          </tr>
          <tr>
            <th scope='row' className='t-gray'>
              <FcGlobe className='xs-icon' /> {t('mechanic.zip')}
            </th>
            <td className='t-align-r'>
              {isAuthenticated && edit ? (
                <CustomInput
                  name='zip'
                  placeholder='Zip or Postcode'
                  value={inputs.zip}
                  onChange={onInputsChange}
                  bsSize='sm'
                />
              ) : (
                user?.zip
              )}
            </td>
          </tr>
        </tbody>
      </Table>

      <hr />
      <Row>
        <Col md={6} className='m-b-s'>
          <TextButton onClick={_ => (window.location.href = `tel:${inputs.tel}`)}>
            <FcCellPhone className='s-icon' /> {user?.tel}
          </TextButton>
        </Col>
        <Col md={6} className='m-b-s'>
          <TextButton onClick={toggleQuotewModal}>
            <FcMoneyTransfer className='s-icon' /> {t('buttons.getQuote')}
          </TextButton>
        </Col>
        <Col md={6} className='m-b-s'>
          <TextButton onClick={toggleReviewModal}>
            <FcLike className='s-icon' /> {t('buttons.leaveReview')}
          </TextButton>
        </Col>
        <Col md={6} className='m-b-s'>
          <TextButton onClick={onCopyClick} id='copy-share-link'>
            <FcLink className='s-icon' /> {t('buttons.copyLink')}
          </TextButton>
          <UncontrolledTooltip trigger='click' target='copy-share-link'>
            Copied
          </UncontrolledTooltip>
        </Col>

        {/* {user?.showEmail && (
          <Col md={6} className='m-b-s'>
            <TextButton onClick={_ => (window.location.href = `mailto:${user?.email}`)}>
              <FcInvite className='s-icon' /> {t('buttons.sendEmail')}
            </TextButton>
          </Col>
        )} */}

        {!!user?.website && typeof user?.website === 'string' && (
          <Col md={6} className='m-b-s'>
            <TextButton
              onClick={_ =>
                window.open(user?.website?.startsWith('https://') ? user?.website : `https://${user?.website}`)
              }
            >
              <FcExternal className='s-icon' /> {t('buttons.visitWebsite')}
            </TextButton>
          </Col>
        )}

        <Col md={6} className='m-b-s'>
          <TextButton onClick={onQrClick}>
            <FcGrid className='s-icon' /> Get QR Code
          </TextButton>
        </Col>

        <div style={{ display: 'none' }}>
          {qrText && typeof qrText === 'string' && <QRCode id='qr-code-canvas' value={qrText} />}
        </div>
      </Row>

      <div className='t-gray t-f-s-ss'>{t('general.liability1').replace('{{ cust }}', c.cust)}</div>

      <ReviewModal mechanicId={user?._id} modal={reviewModal} toggle={toggleReviewModal} />
      <QuoteModal mechanicId={user?._id} modal={quoteModal} toggle={toggleQuotewModal} />
    </div>
  );
};

export default Details;
