///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Form,
  FormGroup,
  Label,
  CustomButton,
  UncontrolledTooltip,
  CustomInput,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormText,
} from '@ibiliaze/reactstrap';
import { FullPage, Section, ImagePageContent, ImagePageText, ImagePageButton } from '@ibiliaze/react-base';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { login, register, getUser, putUserPassword } from '../../actions/user';
import { login as adminLogin, register as adminRegister, getAdmin, putAdminPassword } from '../../actions/admin';
import { setAlert } from '../../actions/alert';
import { confirmEmail } from '../../actions/email';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
import { resolveRecaptchaSiteKey } from '../../utils/resolveEnv';
import verifyRecaptcha from '../../utils/verifyRecaptcha';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import LegalButton from '../Layout/LegalButton';
//////////////////////////////////////////////////////////////////////////////////////////

const AuthPage = ({
  // Mechanic
  isAuthenticated,
  login,
  register,
  getUser,
  putUserPassword,
  // Admin
  isAdminAuthenticated,
  adminLogin,
  adminRegister,
  getAdmin,
  putAdminPassword,
  // Misc
  confirmEmail,
  setAlert,
}) => {
  // State
  const [authType, setAuthType] = useState('Register');
  const [adminAuth, setAdminAuth] = useState(false);
  const [modal, setModal] = useState(false);
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [inputs, setInputs] = useState({
    name: '',
    email: '',
    tel: '',
    password: '',
    businessName: '',
    zip: '',
    compType: '',
    noe: '',
    password2: '',
    code: '',
    regKey: '',
    legalChecked: false,
  });

  // Helper functions
  const auth = async () => {
    try {
      const req =
        authType === 'Register'
          ? adminAuth
            ? adminRegister
            : register
          : authType === 'Reset Password'
          ? adminAuth
            ? putAdminPassword
            : putUserPassword
          : adminAuth
          ? adminLogin
          : login;
      await req({ ...inputs, status: 'Not verified' });
    } catch (e) {
      console.error(e);
    }
  };

  // Translation
  const [t] = useTranslation('global');
  const compTypeOptions = t('options.compTypeOptions', { returnObjects: true });

  // Toggle functions
  const toggle = _ => setModal(!modal);

  // History
  const navigate = useNavigate();

  // onChange functions
  const onInputsChange = e => setInputs(c => ({ ...c, [e.target.name]: e.target.value }));
  const onRecaptchaChange = async value => setRecaptchaVerified(await verifyRecaptcha(value));

  // onClick functions
  const onRegisterClick = async _ => await auth();

  // Lifecycle hooks
  useEffect(() => {
    // If authentication is succesful redirect to the home page and set the current object states
    const req = async _ => {
      try {
        if (isAuthenticated) {
          navigate('/profile');
          await getUser();
        }

        if (isAdminAuthenticated) {
          navigate('/admin');
          await getAdmin();
        }
      } catch (e) {
        console.log(e);
      }
    };
    req();
  }, [isAuthenticated, isAdminAuthenticated]);

  // onSubmit function
  const onSubmit = async e => {
    e.preventDefault();

    try {
      if (authType !== 'Login' && inputs.password !== inputs.password2) {
        setAlert('Passwords do not match', true, null, null);
      } else if (authType !== 'Login') {
        await confirmEmail({ email: inputs.email?.toLowerCase() });
        toggle();
      } else {
        await auth();
      }
    } catch (e) {
      console.error(e);
    }
  };

  // JSX
  return (
    <FullPage>
      <img alt='engineer' src='/img/auth.jpg' className='page-img' />
      <Section className='below-header custom-page p-t-m'>
        <ImagePageContent>
          <h1 className='t-align-c no-m'>
            <ImagePageText>
              {adminAuth && t('general.admin')} {t(`authPage.authType.${authType}`)}
            </ImagePageText>
          </h1>
          <br />
          <Form onSubmit={onSubmit}>
            {authType === 'Register' && (
              <h5 className='p-b-s'>
                <ImagePageText>{t('authPage.aboutYourself')}</ImagePageText>
              </h5>
            )}
            {authType === 'Register' && (
              <FormGroup floating>
                <CustomInput
                  required
                  placeholder=''
                  type='text'
                  name='name'
                  value={inputs.name}
                  onChange={onInputsChange}
                />
                <Label>{t('mechanic.name')} *</Label>
              </FormGroup>
            )}
            <Row>
              <Col md={authType === 'Register' ? 6 : 12}>
                <FormGroup floating>
                  <CustomInput
                    required
                    placeholder=''
                    type='email'
                    name='email'
                    value={inputs.email}
                    onChange={onInputsChange}
                  />
                  <Label>{t('inputs.email.label')} *</Label>
                </FormGroup>
              </Col>
              <Col md={6}>
                {authType === 'Register' && (
                  <FormGroup floating>
                    <CustomInput
                      required
                      placeholder=''
                      type='number'
                      name='tel'
                      value={inputs.tel}
                      onChange={onInputsChange}
                    />
                    <Label>{t('mechanic.tel')} *</Label>
                  </FormGroup>
                )}
              </Col>
            </Row>

            {authType === 'Register' && adminAuth && (
              <FormGroup floating>
                <CustomInput
                  required
                  placeholder=''
                  type='text'
                  name='regKey'
                  value={inputs.regKey}
                  onChange={onInputsChange}
                />
                <Label>{t('inputs.adminKey.label')} *</Label>
              </FormGroup>
            )}

            {authType === 'Register' && !adminAuth && (
              <>
                <br />
                <h5 className='p-b-s'>
                  <ImagePageText>{t('authPage.aboutYourBusiness')}</ImagePageText>
                </h5>

                <FormGroup floating>
                  <CustomInput
                    required
                    placeholder=''
                    type='text'
                    name='businessName'
                    value={inputs.businessName}
                    onChange={onInputsChange}
                  />
                  <Label>{t('mechanic.businessName')} *</Label>
                </FormGroup>

                <FormGroup floating>
                  <CustomInput
                    required
                    placeholder=''
                    type='text'
                    name='zip'
                    value={inputs.zip}
                    onChange={onInputsChange}
                  />
                  <Label>{t('mechanic.zip')} *</Label>
                </FormGroup>

                <Row>
                  <Col md={6}>
                    <FormGroup floating>
                      <CustomInput
                        required
                        placeholder=''
                        type='select'
                        name='compType'
                        value={inputs.compType}
                        onChange={onInputsChange}
                      >
                        <option value={t('options.select.value')}>{t('options.select.label')}</option>
                        {compTypeOptions.map((o, i) => (
                          <option key={i} value={o.value}>
                            {o.label}
                          </option>
                        ))}
                      </CustomInput>
                      <Label>{t('mechanic.compType')} *</Label>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup floating>
                      <CustomInput
                        required
                        placeholder=''
                        type='select'
                        name='noe'
                        value={inputs.noe}
                        onChange={onInputsChange}
                      >
                        <option value={t('options.select.value')}>{t('options.select.label')}</option>
                        {c.inputsOptions.noe.map((val, i) => (
                          <option key={i}>{val}</option>
                        ))}
                      </CustomInput>
                      <Label>{t('mechanic.noe')} *</Label>
                    </FormGroup>
                  </Col>
                </Row>
              </>
            )}
            {authType === 'Register' && <br />}
            <FormGroup floating>
              <CustomInput
                required
                placeholder=''
                type='password'
                name='password'
                value={inputs.password}
                onChange={onInputsChange}
              />
              <Label>{t('inputs.password.label')} *</Label>
            </FormGroup>
            {authType !== 'Login' && (
              <FormGroup floating>
                <CustomInput
                  required
                  placeholder=''
                  type='password'
                  name='password2'
                  value={inputs.password2}
                  onChange={onInputsChange}
                />
                <Label>{t('inputs.password2.label')} *</Label>
              </FormGroup>
            )}

            <ImagePageText>
              {authType === 'Login' ? `${t('authPage.dontHaveAnAccount')} ` : `${t('authPage.haveAnAccount')} `}
            </ImagePageText>
            <ImagePageButton onClick={_ => setAuthType(authType !== 'Login' ? 'Login' : 'Register')}>
              {authType !== 'Login' ? t('buttons.login') : t('buttons.register')}
            </ImagePageButton>

            <br />

            <ImagePageText>{t('authPage.forgotYourPassword')} </ImagePageText>
            <ImagePageButton onClick={_ => setAuthType('Reset Password')}>{t('buttons.resetPassword')}</ImagePageButton>

            <br />

            <ImagePageButton onClick={_ => setAdminAuth(!adminAuth)}>
              {adminAuth ? t('authPage.imMechanic') : t('authPage.imAdmin')}
            </ImagePageButton>

            <br />
            <br />
            <CustomButton type='submit' color='primary' id='save-button'>
              {authType}
            </CustomButton>
            <UncontrolledTooltip placement='top' target='save-button'>
              {authType}
            </UncontrolledTooltip>
          </Form>
        </ImagePageContent>
      </Section>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t('inputs.emailOtp.label')}</ModalHeader>
        <ModalBody>
          <FormGroup floating>
            <CustomInput
              required
              placeholder=''
              type='number'
              name='code'
              value={inputs.code}
              onChange={onInputsChange}
            />
            <Label>{t('inputs.emailOtp.label')} *</Label>
            <FormText>{t('inputs.emailOtp.formtext')}</FormText>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <ReCAPTCHA sitekey={resolveRecaptchaSiteKey()} onChange={onRecaptchaChange} className='m-auto' />
          <LegalButton inputs={inputs} setInputs={setInputs} />
          <Button
            disabled={!recaptchaVerified || !inputs.legalChecked}
            color='primary'
            onClick={onRegisterClick}
            className='w-100'
          >
            {authType}
          </Button>
        </ModalFooter>
      </Modal>
    </FullPage>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.user.isAuthenticated,
  isAdminAuthenticated: state.admin.isAuthenticated,
});

export default connect(mapStateToProps, {
  login,
  adminLogin,
  register,
  adminRegister,
  getUser,
  getAdmin,
  setAlert,
  putUserPassword,
  putAdminPassword,
  confirmEmail,
})(AuthPage);
