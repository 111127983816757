///////////////////////////////////////////////////////////////////////////////////MODULES
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Row } from '@ibiliaze/reactstrap';
import { Sidebar } from '@ibiliaze/react-sidebar';
import { useTranslation } from 'react-i18next';
import { FullPage, Section } from '@ibiliaze/react-base';
import {
  FcCellPhone,
  FcAbout,
  FcHighPriority,
  FcVoicePresentation,
  FcPositiveDynamic,
  FcBusinessman,
  FcLineChart,
  FcList,
} from 'react-icons/fc';
/////////////////////////////////////////////////////////////////////////////////////HOOKS
import useWindowSize from '../../hooks/useWindowSize';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Banner from '../Admin/Banner';
import Contact from '../Admin/Contact';
import Pricing from '../Admin/Pricing';
import Logs from '../Admin/Logs/Logs';
import Reviews from '../Admin/Reviews/Reviews';
import Complaints from '../Admin/Complaints/Complaints';
import Leads from '../Admin/Leads/Leads';
import Mechanics from '../Admin/Mechanics/Mechanics';
//////////////////////////////////////////////////////////////////////////////////////////

const AdminPage = ({ admin }) => {
  // State
  const [section, setSection] = useState('Mechanics');

  // Hooks
  const windowSize = useWindowSize();

  // Translation
  const [t] = useTranslation('global');

  // Navigate
  const navigate = useNavigate();

  // Lifecycle hooks
  useEffect(() => {
    if (!admin?.isAuthenticated) {
      navigate('/');
    }
  }, []);

  // JSX
  return (
    <FullPage className='below-header custom-page'>
      <Section>
        <h1 className='no-m'>{t('adminPage.header')}</h1>
        <br />
        <Row>
          <Col md={3} className='p-b-m'>
            <Sidebar
              section={section}
              setSection={setSection}
              mobileWidth={c.mainPic.mobileWidth}
              windowWidth={windowSize.width}
              sections={[
                {
                  title: (
                    <>
                      <FcBusinessman className='s-icon' /> {t('sidebar.mechanics')}
                    </>
                  ),
                  id: 'Mechanics',
                },
                {
                  title: (
                    <>
                      <FcVoicePresentation className='s-icon' /> {t('sidebar.reviews')}
                    </>
                  ),
                  id: 'Reviews',
                },
                {
                  title: (
                    <>
                      <FcPositiveDynamic className='s-icon' /> {t('sidebar.leads')}
                    </>
                  ),
                  id: 'Leads',
                },
                {
                  title: (
                    <>
                      <FcAbout className='s-icon' /> {t('sidebar.banner')}
                    </>
                  ),
                  id: 'Banner',
                },
                {
                  title: (
                    <>
                      <FcLineChart className='s-icon' /> {t('sidebar.pricing')}
                    </>
                  ),
                  id: 'Pricing',
                },
                {
                  title: (
                    <>
                      <FcHighPriority className='s-icon' /> {t('sidebar.complaints')}
                    </>
                  ),
                  id: 'Complaints',
                },
                {
                  title: (
                    <>
                      <FcCellPhone className='s-icon' /> {t('sidebar.contact')}
                    </>
                  ),
                  id: 'Contact',
                },
                {
                  title: (
                    <>
                      <FcList className='s-icon' /> {t('sidebar.logs')}
                    </>
                  ),
                  id: 'Logs',
                },
              ]}
            />
          </Col>
          <Col md={9} className='p-b-m'>
            <div className={windowSize.width > c.mainPic.mobileWidth ? 'user-section' : 'user-section-mobile'}>
              <h4 className='t-align-c'>{t(`sidebar.${section.toLowerCase()}`)}</h4>
              <hr />
              {section === 'Mechanics' ? (
                <Mechanics />
              ) : section === 'Banner' ? (
                <Banner />
              ) : section === 'Contact' ? (
                <Contact />
              ) : section === 'Reviews' ? (
                <Reviews />
              ) : section === 'Pricing' ? (
                <Pricing />
              ) : section === 'Logs' ? (
                <Logs />
              ) : section === 'Leads' ? (
                <Leads />
              ) : (
                <Complaints />
              )}
            </div>
          </Col>
        </Row>
      </Section>
    </FullPage>
  );
};

const mapStateToProps = state => ({ admin: state.admin });

export default connect(mapStateToProps)(AdminPage);
