///////////////////////////////////////////////////////////////////////////////////MODULES
import { Col, CustomInput, FormGroup, Input, Label, Table } from '@ibiliaze/reactstrap';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FcGlobe, FcBusinessman, FcPieChart, FcIdea } from 'react-icons/fc';
import countryList from 'react-select-country-list';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import EditSaveButton from './EditSaveButton';
//////////////////////////////////////////////////////////////////////////////////////////

const CompanyInfo = ({ user, isAuthenticated, putUser }) => {
  // State
  const [edit, setEdit] = useState(false);
  const [inputs, setInputs] = useState({
    description: '',
    slogan: '',
    workRadius: 0,
    country: '',
    address: '',
    zip: '',
  });

  // Translation
  const [t] = useTranslation('global');

  // Helper functions
  const countryOptions = useMemo(() => countryList().getData(), []);

  // onChange functions
  const onInputsChange = e => setInputs(c => ({ ...c, [e.target.name]: e.target.value }));
  const onWorkRadiusChange = e => setInputs(c => ({ ...c, workRadius: Number(e.target.value) || 0 }));

  // Lifecycle hooks
  useEffect(() => {
    try {
      setInputs({
        description: user?.description,
        slogan: user?.slogan,
        workRadius: user?.workRadius,
        country: user?.country,
        address: user?.address,
        zip: user?.zip,
      });
    } catch (e) {
      console.error(e);
    }
  }, [user]);

  // JSX
  return (
    <>
      {isAuthenticated && <EditSaveButton inputs={inputs} edit={edit} setEdit={setEdit} putUser={putUser} />}

      {isAuthenticated && edit ? (
        <FormGroup>
          <Label>{t('mechanic.description')}</Label>
          <CustomInput
            type='textarea'
            rows='10'
            name='description'
            placeholder={t('mechanic.description')}
            value={inputs.description}
            onChange={onInputsChange}
          />
        </FormGroup>
      ) : (
        <>
          <h5>{t('mechanic.description')}</h5>
          <p className='t-ws-p'>{user?.description}</p>
        </>
      )}

      <hr />

      <Col md={8}>
        <Table borderless>
          <tbody>
            <tr>
              <th scope='row' className='t-gray'>
                <FcBusinessman className='s-icon' /> {t(c.profession)} ID
              </th>
              <td>{user?._id}</td>
            </tr>

            {isAuthenticated && (
              <>
                <tr>
                  <th scope='row' className='t-gray'>
                    <FcIdea className='s-icon' /> {t('mechanic.slogan')}
                  </th>
                  <td>
                    {isAuthenticated && edit ? (
                      <FormGroup>
                        <CustomInput
                          placeholder={t('mechanic.slogan')}
                          name='slogan'
                          value={inputs.slogan}
                          onChange={onInputsChange}
                        />
                      </FormGroup>
                    ) : (
                      user?.slogan
                    )}
                  </td>
                </tr>

                <tr>
                  <th scope='row' className='t-gray'>
                    <FcGlobe className='s-icon' /> {t('mechanic.address')}
                  </th>
                  <td>
                    {isAuthenticated && edit ? (
                      <FormGroup>
                        <CustomInput
                          placeholder='123 Avenue'
                          name='address'
                          value={inputs.address}
                          onChange={onInputsChange}
                        />
                      </FormGroup>
                    ) : (
                      user?.address
                    )}
                  </td>
                </tr>

                <tr>
                  <th scope='row' className='t-gray'>
                    <FcGlobe className='s-icon' /> {t('mechanic.zip')}
                  </th>
                  <td>
                    {isAuthenticated && edit ? (
                      <FormGroup>
                        <CustomInput placeholder='AL2000' name='zip' value={inputs.zip} onChange={onInputsChange} />
                      </FormGroup>
                    ) : (
                      user?.zip
                    )}
                  </td>
                </tr>

                <tr>
                  <th scope='row' className='t-gray'>
                    <FcGlobe className='s-icon' /> {t('mechanic.country')}
                  </th>
                  <td>
                    {isAuthenticated && edit ? (
                      <FormGroup>
                        <CustomInput
                          type='select'
                          options={countryOptions}
                          placeholder='Country'
                          name='country'
                          value={inputs.country}
                          onChange={onInputsChange}
                        >
                          <option></option>
                          {countryOptions.map((val, i) => (
                            <option key={i}>{val.label}</option>
                          ))}
                        </CustomInput>
                      </FormGroup>
                    ) : (
                      user?.country
                    )}
                  </td>
                </tr>
              </>
            )}

            <tr>
              <th scope='row' className='t-gray'>
                <FcPieChart className='s-icon' /> {t('mechanic.workRadius')}
              </th>
              <td>
                {isAuthenticated && edit ? (
                  <FormGroup>
                    {inputs.workRadius || 0}km
                    <Input
                      type='range'
                      name='workRadius'
                      value={inputs.workRadius}
                      onChange={onWorkRadiusChange}
                      min={0}
                      max={350}
                      step={1}
                    />
                  </FormGroup>
                ) : (
                  `${user?.workRadius}km`
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </>
  );
};

export default CompanyInfo;
