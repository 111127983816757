///////////////////////////////////////////////////////////////////////////////////MODULES
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  CustomInput,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  Input,
  FormText,
} from '@ibiliaze/reactstrap';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { confirmEmail } from '../../../actions/email';
import { postReview, putReview, deleteReview } from '../../../actions/reviews';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveRecaptchaSiteKey } from '../../../utils/resolveEnv';
import verifyRecaptcha from '../../../utils/verifyRecaptcha';
//////////////////////////////////////////////////////////////////////////////////////////

const ReviewModal = ({ review, mechanicId, modal, toggle, confirmEmail, postReview, putReview, deleteReview }) => {
  // State
  const [section, setSection] = useState('inputs');
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [ipAddress, setIpAddress] = useState(null);
  const [inputs, setInputs] = useState({
    // Inputs section
    name: '',
    email: '',
    tel: '',
    job: '',
    body: '',
    // Ratings section
    qualityOfWork: 10,
    reliability: 10,
    tidiness: 10,
    communication: 10,
    // Email OTP section
    emailCode: '',
  });

  // Translation
  const [t] = useTranslation('global');

  // onChange functions
  const onInputsChange = e => setInputs(c => ({ ...c, [e.target.name]: e.target.value }));
  const onRecaptchaChange = async value => setRecaptchaVerified(await verifyRecaptcha(value));

  // onClick functions
  const onDeleteClick = async _ => {
    try {
      await deleteReview(review?._id);
    } catch (e) {
      console.error(e);
    }
  };

  // Lifecycle hooks
  useEffect(() => {
    const req = async _ => {
      if (section === 'emailOtp') {
        await confirmEmail({ email: inputs.email?.toLowerCase() });
      }
    };

    req();
  }, [section]);

  useEffect(() => {
    try {
      if (!!review) {
        setInputs({ ...review });
      }
    } catch (e) {
      console.error(e);
    }
  }, [review]);

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        setIpAddress(data.ip);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    fetchIpAddress();
  }, []);

  // onSubmit functions
  const onSubmit = async e => {
    e.preventDefault();

    const submit = async () => {
      if (!!review) {
        await putReview({ endpoint: `/${review._id}`, ...inputs, ip: ipAddress, verified: false });
      } else {
        await postReview({ ...inputs, mechanicId, ip: ipAddress, verified: false });
      }
      toggle();
    };

    try {
      section === 'inputs' ? setSection('ratings') : section === 'ratings' ? setSection('emailOtp') : await submit();
    } catch (e) {
      console.error(e);
    }
  };

  // JSX
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <Form onSubmit={onSubmit}>
        <ModalHeader toggle={toggle}>{t('buttons.leaveReview')}</ModalHeader>
        <ModalBody>
          {section === 'inputs' ? (
            <>
              <FormGroup floating>
                <CustomInput required name='name' placeholder='Name *' value={inputs.name} onChange={onInputsChange} />
                <Label>{t('mechanic.name')} *</Label>
              </FormGroup>

              <FormGroup floating>
                <CustomInput
                  required
                  type='email'
                  name='email'
                  placeholder=''
                  value={inputs.email}
                  onChange={onInputsChange}
                />
                <Label>{t('inputs.email.label')} *</Label>
              </FormGroup>

              <FormGroup floating>
                <CustomInput
                  required
                  placeholder=''
                  type='number'
                  name='tel'
                  value={inputs.tel}
                  onChange={onInputsChange}
                />
                <Label>{t('mechanic.tel')} *</Label>
              </FormGroup>

              <FormGroup floating>
                <CustomInput required placeholder='' name='job' value={inputs.job} onChange={onInputsChange} />
                <Label>{t('inputs.toj.label')} *</Label>
              </FormGroup>

              <FormGroup>
                <Label>{t('inputs.description.label')} *</Label>
                <CustomInput
                  required
                  type='textarea'
                  rows='5'
                  placeholder={t('inputs.description.label')}
                  name='body'
                  value={inputs.body}
                  onChange={onInputsChange}
                />
              </FormGroup>
            </>
          ) : section === 'ratings' ? (
            <>
              <FormGroup>
                <Label>
                  <span className='text-gradient t-f-s-ll'>{inputs.qualityOfWork}</span>
                  <span className='t-f-s-s'>/10</span> - {t('ratings.qualityOfWork')}
                </Label>
                <Input
                  type='range'
                  name='qualityOfWork'
                  value={inputs.qualityOfWork}
                  onChange={onInputsChange}
                  min={0}
                  max={10}
                  step={1}
                />
              </FormGroup>

              <FormGroup>
                <Label>
                  <span className='text-gradient t-f-s-ll'>{inputs.reliability}</span>
                  <span className='t-f-s-s'>/10</span> - {t('ratings.reliability')}
                </Label>
                <Input
                  type='range'
                  name='reliability'
                  value={inputs.reliability}
                  onChange={onInputsChange}
                  min={0}
                  max={10}
                  step={1}
                />
              </FormGroup>

              <FormGroup>
                <Label>
                  <span className='text-gradient t-f-s-ll'>{inputs.tidiness}</span>
                  <span className='t-f-s-s'>/10</span> - {t('ratings.tidiness')}
                </Label>
                <Input
                  type='range'
                  name='tidiness'
                  value={inputs.tidiness}
                  onChange={onInputsChange}
                  min={0}
                  max={10}
                  step={1}
                />
              </FormGroup>

              <FormGroup>
                <Label>
                  <span className='text-gradient t-f-s-ll'>{inputs.communication}</span>
                  <span className='t-f-s-s'>/10</span> - {t('ratings.communication')}
                </Label>
                <Input
                  type='range'
                  name='communication'
                  value={inputs.communication}
                  onChange={onInputsChange}
                  min={0}
                  max={10}
                  step={1}
                />
              </FormGroup>
            </>
          ) : (
            <>
              <FormGroup floating>
                <CustomInput
                  required
                  placeholder=''
                  type='number'
                  name='emailCode'
                  value={inputs.emailCode}
                  onChange={onInputsChange}
                />
                <Label>{t('inputs.emailOtp.label')} *</Label>
                <FormText>{t('inputs.emailOtp.formtext')}</FormText>
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          {section === 'emailOtp' && (
            <ReCAPTCHA sitekey={resolveRecaptchaSiteKey()} onChange={onRecaptchaChange} className='m-auto' />
          )}
          {section !== 'inputs' ? (
            <Button
              color='secondary'
              onClick={_ => setSection(section === 'ratings' ? 'inputs' : 'ratings')}
              className='w-100'
            >
              {t('buttons.back')}
            </Button>
          ) : !!review ? (
            <Button color='danger' className='w-100' onClick={onDeleteClick}>
              {t('buttons.delete')}
            </Button>
          ) : (
            <></>
          )}
          <Button
            disabled={section === 'emailOtp' && !recaptchaVerified}
            color='primary'
            type='submit'
            className='w-100'
          >
            {t('buttons.continue')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default connect(null, { confirmEmail, postReview, putReview, deleteReview })(ReviewModal);
