///////////////////////////////////////////////////////////////////////////////////MODULES
import { Col, MainImage, Row } from '@ibiliaze/reactstrap';
import { FadeIn } from '@ibiliaze/react-intersect';
import { useTranslation } from 'react-i18next';
import { FullPage, Section } from '@ibiliaze/react-base';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const WhyUsPage = () => {
  // Translation
  const [t] = useTranslation('global');

  //JSX
  return (
    <FullPage>
      <FadeIn>
        <MainImage
          mobileWidth={c.mainPic.mobileWidth}
          formGroup={false}
          height='300px'
          mobileHeight='300px'
          // Img
          imgPath='/img/whyus.jpg'
          // Title
          title={t('headers.whyUs')}
        />
      </FadeIn>
      <Section className='custom-page p-b-m p-t-l'>
        <Row>
          {t('whyUs', { returnObjects: true }).map((item, i) => (
            <Col key={i} md={6}>
              <div class='reason'>
                <h4>{item.header.replace('{{ cust }}', c.cust)}</h4>
                <p>{item.body.replace('{{ cust }}', c.cust)}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Section>
    </FullPage>
  );
};

export default WhyUsPage;
