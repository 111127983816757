///////////////////////////////////////////////////////////////////////////////////MODULES
import { useNavigate } from 'react-router-dom';
import { Col, Row } from '@ibiliaze/reactstrap';
import { useTranslation } from 'react-i18next';
import { TransformYDown, FadeIn } from '@ibiliaze/react-intersect';
import { Multirow } from '@ibiliaze/react-multirow';
import { Section } from '@ibiliaze/react-base';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
import { Engine, Electric, Brush, Service, Tire, Wheel } from '../../utils/icons';
//////////////////////////////////////////////////////////////////////////////////////////

const About = () => {
  // Translation
  const [t] = useTranslation('global');

  // Navigate
  const navigate = useNavigate();

  // JSX
  return (
    <Section id='about-section' className='custom-page p-t-m'>
      <br />

      <Row>
        {c.abouts.map((a, i) => (
          <Col key={i} md={4}>
            <Multirow
              img={a.img}
              alt={a.alt}
              header={t(a.header)}
              icon={a.icon}
              body={t(a.body).replace('{{ cust }}', c.cust)}
              onClick={a.onClick}
            />
          </Col>
        ))}
      </Row>

      <FadeIn>
        <h1 className='t-align-c'>
          {t('headers.about')} <span className='text-gradient'>{t('headers.aboutHighlight')}</span>
        </h1>
      </FadeIn>

      <Row>
        <Col onClick={_ => navigate(`/mechanics?skill=${t('skills.tuning').toLowerCase()}`)}>
          <TransformYDown>
            <div className='info-div'>
              <Engine />
              <br />
              <br />
              <h5>{t('skills.tuning')}</h5>
            </div>
          </TransformYDown>
        </Col>

        <Col onClick={_ => navigate(`/mechanics?skill=${t('skills.electrician').toLowerCase()}`)}>
          <TransformYDown>
            <div className='info-div'>
              <Electric />
              <br />
              <br />
              <h5>{t('skills.electrician')}</h5>
            </div>
          </TransformYDown>
        </Col>

        <Col onClick={_ => navigate(`/mechanics?skill=${t('skills.detailer').toLowerCase()}`)}>
          <TransformYDown>
            <div className='info-div'>
              <Brush />
              <br />
              <br />
              <h5>{t('skills.detailer')}</h5>
            </div>
          </TransformYDown>
        </Col>

        <Col onClick={_ => navigate(`/mechanics?skill=${t('skills.service').toLowerCase()}`)}>
          <TransformYDown>
            <div className='info-div'>
              <Service />
              <br />
              <br />
              <h5>{t('skills.service')}</h5>
            </div>
          </TransformYDown>
        </Col>

        <Col onClick={_ => navigate(`/mechanics?skill=${t('skills.tires').toLowerCase()}`)}>
          <TransformYDown>
            <div className='info-div'>
              <Tire />
              <br />
              <br />
              <h5>{t('skills.tires')}</h5>
            </div>
          </TransformYDown>
        </Col>

        <Col onClick={_ => navigate(`/mechanics?skill=${t('skills.alloys').toLowerCase()}`)}>
          <TransformYDown>
            <div className='info-div'>
              <Wheel />
              <br />
              <br />
              <h5>{t('skills.alloys')}</h5>
            </div>
          </TransformYDown>
        </Col>
      </Row>
    </Section>
  );
};

export default About;
