/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from '../actions/types/types';
//////////////////////////////////////////////////////////////////COMPLAINTS DEFAULT STATE
const complaintsDefaultState = [];
////////////////////////////////////////////////////////////////////////COMPLAINTS REDUCER
const complaints = (state = complaintsDefaultState, action) => {
  switch (action.type) {
    // GET request
    case types.GET_COMPLAINTS_SUCCESS:
      return Array.isArray(action.complaints) ? [...action.complaints] : [action.complaints];
    case types.GET_COMPLAINTS_FAILURE:
      return [...state];

    // POST request
    case types.POST_COMPLAINT_SUCCESS:
      return [...state, action.complaint];
    case types.POST_COMPLAINT_FAILURE:
      return [...state];

    // PUT request
    case types.PUT_COMPLAINT_SUCCESS:
      return state.map(complaint => {
        if (complaint._id === action.complaint._id) {
          return { ...complaint, ...action.complaint };
        } else {
          return complaint;
        }
      });
    case types.PUT_COMPLAINT_FAILURE:
      return [...state];

    // DELETE request
    case types.DELETE_COMPLAINT_SUCCESS:
      return state.filter(complaint => complaint._id !== action.complaint._id);
    case types.DELETE_COMPLAINT_FAILURE:
      return [...state];

    default:
      return state;
  }
};

export default complaints;
