///////////////////////////////////////////////////////////////////////////////////MODULES
import {
  Badge,
  CustomListGroup,
  CustomListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from '@ibiliaze/reactstrap';
//////////////////////////////////////////////////////////////////////////////////////////

const ReviewsList = ({ reviews, onReviewClick }) => (
  <CustomListGroup>
    {reviews.map((r, i) => (
      <CustomListGroupItem key={i} onClick={_ => onReviewClick(r)} green={r.verified} red={!r.verified}>
        <ListGroupItemHeading>
          {r.email}{' '}
          <Badge className='r-f-s-s' color={r.verified ? 'success' : 'danger'} pill>
            {r.verified ? 'Verified' : 'Not verified'}
          </Badge>
        </ListGroupItemHeading>
        <ListGroupItemText className='r-gray t-f-s-ss'>{r.createdAt}</ListGroupItemText>
      </CustomListGroupItem>
    ))}
  </CustomListGroup>
);

export default ReviewsList;
