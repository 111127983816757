///////////////////////////////////////////////////////////////////////////////////MODULES
import { Button, ButtonGroup } from '@ibiliaze/reactstrap';
import { useTranslation } from 'react-i18next';
//////////////////////////////////////////////////////////////////////////////////////////

const EditSaveButton = ({ inputs, edit, setEdit, putUser }) => {
  // Translation
  const [t] = useTranslation('global');

  // onClick functions
  const onSaveClick = async _ => {
    try {
      await putUser({ ...inputs });
      setEdit(false);
    } catch (e) {
      console.error(e);
    }
  };

  // JSX
  return (
    <>
      <div className='t-align-c'>
        {!edit ? (
          <Button className='t-align-c' color='primary' onClick={_ => setEdit(true)}>
            {t('buttons.edit')}
          </Button>
        ) : (
          <ButtonGroup>
            <Button className='t-align-c' color='success' onClick={onSaveClick}>
              {t('buttons.save')}
            </Button>
            <Button className='t-align-c' color='secondary' onClick={_ => setEdit(false)}>
              {t('buttons.cancel')}
            </Button>
          </ButtonGroup>
        )}
      </div>
      <br />
    </>
  );
};

export default EditSaveButton;
