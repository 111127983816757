///////////////////////////////////////////////////////////////////////////////////MODULES
import { Table } from '@ibiliaze/reactstrap';
import { useTranslation } from 'react-i18next';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const MechanicInputsTable = ({ mechanic }) => {
  // Translation
  const [t] = useTranslation('global');

  // JSX
  return (
    <Table borderless>
      <tbody>
        <tr>
          <th scope='row' className='t-f-s-l'>
            {t('admin.companyDetails')}
          </th>
          <td />
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t(c.profession)} ID
          </th>
          <td className='t-align-r'>{mechanic._id}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t('mechanic.businessName')}
          </th>
          <td className='t-align-r'>{mechanic.businessName}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t('admin.owner')}
          </th>
          <td className='t-align-r'>{mechanic.name}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t('mechanic.compType')}
          </th>
          <td className='t-align-r'>{mechanic.compType}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t('mechanic.noe')}
          </th>
          <td className='t-align-r'>{mechanic.noe}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t('mechanic.slogan')}
          </th>
          <td className='t-align-r'>{mechanic.slogan}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t('mechanic.address')}
          </th>
          <td className='t-align-r'>{mechanic.address}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t('mechanic.zip')}
          </th>
          <td className='t-align-r'>{mechanic.zip}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t('mechanic.country')}
          </th>
          <td className='t-align-r'>{mechanic.country}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t('mechanic.insurer')}
          </th>
          <td className='t-align-r'>{mechanic.insurer}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t('mechanic.insuranceExpiry')}
          </th>
          <td className='t-align-r'>{mechanic.insuranceExpiry}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t('mechanic.vat')}
          </th>
          <td className='t-align-r'>{mechanic.vat}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t('mechanic.workRadius')}
          </th>
          <td className='t-align-r'>{mechanic.workRadius}km</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t('mechanic.description')}
          </th>
          <td className='t-align-r'>{mechanic.description}</td>
        </tr>

        <tr>
          <th scope='row' className='t-f-s-l'>
            {t('admin.contactDetails')}
          </th>
          <td />
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t('inputs.email.label')}
          </th>
          <td className='t-align-r'>{mechanic.email}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t('mechanic.tel')}
          </th>
          <td className='t-align-r'>{mechanic.tel}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t('mechanic.tel')} 2
          </th>
          <td className='t-align-r'>{mechanic.tel2}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t('mechanic.tel')} 3
          </th>
          <td className='t-align-r'>{mechanic.tel3}</td>
        </tr>
        <tr>
          <th scope='row' className='t-gray'>
            {t('mechanic.website')}
          </th>
          <td className='t-align-r'>{mechanic.website}</td>
        </tr>

        <tr>
          <th scope='row' className='t-f-s-l'>
            {t('mechanic.accreditations')}
          </th>
          <td />
        </tr>

        {!!mechanic.accreditations &&
          Array.isArray(mechanic.accreditations) &&
          mechanic?.accreditations?.map((a, i) => (
            <tr key={i}>
              <th scope='row' className='t-gray'>
                {a.name}
              </th>
              <td className='t-align-r t-f-s-ss'>
                <div>{a.dateAttained}</div>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

export default MechanicInputsTable;
