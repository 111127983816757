///////////////////////////////////////////////////////////////////////////////////MODULES
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CustomButton, CustomInput, Form, FormGroup, Label, Spinner } from '@ibiliaze/reactstrap';
import { useTranslation } from 'react-i18next';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { putSettings } from '../../actions/settings';
//////////////////////////////////////////////////////////////////////////////////////////

const Contact = ({ settings, putSettings }) => {
  // State
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    tel: '',
    tel2: '',
    tel3: '',
    email: '',
    email2: '',
    email3: '',
  });

  // Translation
  const [t] = useTranslation('global');

  // onChange functions
  const onInputsChange = e => setInputs(c => ({ ...c, [e.target.name]: e.target.value }));

  // Lifecycle hooks
  useEffect(() => {
    try {
      setInputs({ ...settings.global });
    } catch (e) {
      console.error(e);
    }
  }, [settings]);

  // onSubmit functions
  const onSubmit = async e => {
    e.preventDefault();
    try {
      setLoading(true);
      await putSettings({ ...inputs, type: undefined });
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  // JSX
  return loading ? (
    <Spinner color='primary' />
  ) : (
    <Form onSubmit={onSubmit}>
      <FormGroup floating>
        <CustomInput type='number' name='tel' placeholder='' value={inputs.tel} onChange={onInputsChange} />
        <Label>{t('inputs.phone.label')}</Label>
      </FormGroup>

      <FormGroup floating>
        <CustomInput type='number' name='tel2' placeholder='' value={inputs.tel2} onChange={onInputsChange} />
        <Label>{t('inputs.phone.label')} 2</Label>
      </FormGroup>

      <FormGroup floating>
        <CustomInput type='number' name='tel3' placeholder='' value={inputs.tel3} onChange={onInputsChange} />
        <Label>{t('inputs.phone.label')} 3</Label>
      </FormGroup>

      <FormGroup floating>
        <CustomInput name='email' placeholder='' value={inputs.email} onChange={onInputsChange} />
        <Label>{t('inputs.email.label')}</Label>
      </FormGroup>

      <FormGroup floating>
        <CustomInput name='email2' placeholder='' value={inputs.email2} onChange={onInputsChange} />
        <Label>{t('inputs.email.label')} 2</Label>
      </FormGroup>

      <FormGroup floating>
        <CustomInput name='email3' placeholder='' value={inputs.email3} onChange={onInputsChange} />
        <Label>{t('inputs.email.label')} 3</Label>
      </FormGroup>

      <FormGroup>
        <CustomButton type='submit' color='primary'>
          {t('buttons.save')}
        </CustomButton>
      </FormGroup>
    </Form>
  );
};

const mapStateToProps = state => ({ settings: state.settings });

export default connect(mapStateToProps, { putSettings })(Contact);
